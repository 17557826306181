import React, {useState, useCallback, useEffect, useContext} from 'react';
import {View, SafeAreaView, StyleSheet, Platform, TouchableOpacity, AppState} from "react-native";
import {NavigationContainer, useNavigation} from '@react-navigation/native';
import * as Font from 'expo-font';
import HeaderIcon from './assets/HeaderIcon';
import CreateScreen from "./screens/CreateScreen";
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { registerForPushNotificationsAsync} from "./utils/NotificationService";

import LoginScreen from "./screens/Login";
import SignUpScreen from "./screens/SignUpScreen";
import TutorialScreen from "./screens/TutorialScreen";
import SupportScreen from "./screens/SupportScreen";
import NotificationsScreen from "./screens/NotificationsScreen";
import NotificationSettingsScreen from "./screens/NotificationSettingsScreen";
import EmailSignUpScreen from "./screens/EmailSignUpScreen";
import PhoneSignUpScreen from "./screens/PhoneSignUpScreen";
import SvgHome from "./assets/images/Home";
import SvgGenerate from "./assets/images/Generate";
import SvgProfile from "./assets/images/Profile";
import SvgHomeWhite from "./assets/images/HomeWhite";
import AllCardsScreen from './screens/AllCardsScreen';
import SvgGenerateWhite from "./assets/images/GenerateWhite";
import SvgProfileWhite from "./assets/images/ProfileWhite";
import SvgFabylHeader from './assets/images/FabylHeader';
import CompleteNewPasswordScreen from "./screens/CompleteNewPasswordScreen";
import ExpandedInputScreen from "./components/ExpandedInputScreen";
import WorldDetailScreen from "./screens/WorldDetailScreen";
import UserWorldsListScreen from "./screens/UserWorldsListScreen";
import CreateWorldScreen from "./screens/CreateWorldScreen";
import FabylCreationScreen from "./screens/FabylCreationScreen";
import WorldCreationScreen from "./screens/WorldCreationScreen";
import {useAssets} from "expo-asset";
import UnauthPublicProfile from "./screens/UnauthPublicProfile";
import FriendRequestsScreen from "./screens/FriendRequestsScreen";
import * as SplashScreen from 'expo-splash-screen';
import {fetchSuggestedTitles} from "./hooks/useSuggestedTitles";
import {fetchCards} from "./utils/api/cardsService";
import API_ENDPOINTS from "./apiEndpoints";


import OtpScreen from "./screens/OtpScreen";
import { ThemeProvider } from './styles/ThemeContext';
import {AudioPlayerProvider} from "./contexts/AudioPlayerContext";
import {AutoplayProvider} from "./contexts/AutoplayContext";
import { RootSiblingParent } from 'react-native-root-siblings';
import YourWorldsScreen from "./screens/YourWorlds";
import FriendSharedWorldsScreen from "./screens/FriendSharedWorlds";
import theme from './styles/theme';
import SetUsernameScreen from "./screens/SetUsernameScreen";
import ChangePasswordScreen from "./screens/ChangePasswordScreen";
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator, DrawerItemList, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack'; // Import this
import FullScreenOverlay from "./screens/FullScreenOverlay";
import { brandColors } from "./styles/variables";
import globalStyles from "./styles/globalStyles";
import PrivateProfileScreen from "./screens/PrivateProfile";
import HomeScreen from "./screens/Home";
import SettingsScreen from "./screens/SettingsScreen";
import UpdateEmailScreen from "./screens/UpdateEmailScreen";
import UpdatePhoneScreen from "./screens/UpdatePhoneScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import SearchScreen from "./screens/SearchScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import RiffCardScreen from "./screens/RiffCardScreen";
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import {ProfileProvider, useProfile} from "./contexts/ProfileContext";
import CardDetailScreen from './screens/CardDetailScreen';
import WorldScreen from "./screens/WorldScreen";
import WorldCollaboratorsScreen from "./screens/WorldCollaboratorsScreen";
import WorldSettingsScreen from "./screens/WorldSettingsScreen";
import FullscreenDetailScreen from './screens/FullscreenDetailScreen';
import PublicProfile from "./screens/PublicProfile";
import UpgradeScreen from "./screens/UpgradeScreen";
import DeactivateAccountScreen from "./screens/DeactivateAccountScreen";
import FollowerFollowingSearchScreen from "./screens/FollowerFollowingSearchScreen";
import FriendsSearchScreen from "./screens/FriendsSearchScreen";
import BlockedUsersSearchScreen from "./screens/BlockedUsersSearchScreen";
import UserSearchScreen from "./screens/UserSearchScreen";
import { QueryClient, QueryClientProvider } from 'react-query';
import { Amplify } from "aws-amplify";
import cognitoUserPool from './aws-exports';
import { StripeProvider } from '@stripe/stripe-react-native';
import * as Linking from 'expo-linking';
import {PushTokenProvider, usePushToken} from "./contexts/PushTokenContext";
import * as Notifications from 'expo-notifications';
import { useWindowDimensions } from 'react-native';
import CustomDrawerContent from "./components/web/CustomDrawerContent";
import MeetTheTeamScreen from "./screens/MeetTheTeamScreen";
import Constants from 'expo-constants';
import CardDetailResolver from "./screens/resolvers/CardDetailResolver";
import ProfileResolver from "./screens/resolvers/ProfileResolver";
// ^ Using env vars from .env file using workaround which works for web and native:
// https://github.com/expo/expo/issues/23812#issuecomment-1697998466
// Hence using Constants.expoConfig.extra.env instead of process.env
import { markNotificationAsRead } from "./utils/api/userService";
import CardDeveloperDetails from "./screens/CardDeveloperDetails";
import LandingPage from "./screens/LandingPage";
import {fetchInitialRandomCards} from "./hooks/useCards";
import WorldDetailResolver from "./screens/resolvers/WorldDetailResolver";

function useIsWideScreen() {
  const { width } = useWindowDimensions();
  return width >= theme.breakpoints.medium; // Define your breakpoint for wide screens
}

Amplify.configure(cognitoUserPool);


const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();
const Stack = createStackNavigator(); // Create a stack navigator
const AuthStack = createStackNavigator(); // Stack for authenticated screens
const UnAuthStack = createStackNavigator(); // Stack for unauthenticated screens

const HomeStack = createStackNavigator();
const CreateStack = createStackNavigator();
const ProfileStack = createStackNavigator();

const SearchStack = createStackNavigator();
const NotificationsStack = createStackNavigator();

const queryClient = new QueryClient();

const linking = {
  prefixes: ['com.weeverze.fabylapp://', 'https://fabyl.ai', Linking.createURL('/')],
  config: {
    screens: {
      Authenticated: {
        screens: {
          Tabs: { // Your Tab Navigator
            screens: {
              Home: { // Your Home Stack Navigator
                screens: {
                  HomeScreen: 'home/',
                  AllCards: 'all/',
                  CardDetail: 'card-detail/:card_uuid',
                  UserProfile: 'profile-detail/:profile_uuid',
                  WorldDetail: 'world-detail/:world_uuid',
                  World: 'cards/:worldUuid',
                },
              },
              Create: {
                screens: {
                  CreateScreen: "create/",
                }
              },
              Profile: {
                screens: {
                  PrivateProfileScreen: 'profile/',
                },
              },
              // Define other tabs here if needed
              Settings: 'settings/',
              UpgradeScreen: 'upgrade/',
            },
          },
          // UpgradeScreen: 'upgrade/',
          // ManageSubscription: 'manage_subscription/',
          // ^ match value in CreateCustomerPortalSession view
          // Other stacks or screens within the Authenticated navigator
        },
      },
      Unauthenticated: {
        screens: {
          LandingPage: '/',
          // Other stacks or screens within the Unauthenticated navigator
        },
      },
      PublicCardDetail: 'public-card-detail/:card_uuid',
      PublicProfile: 'public-profile-detail/:profile_uuid',
      PublicWorldDetail: 'public-world-detail/:world_uuid',
      World: 'public-cards/:worldUuid',
      // Other top-level navigators (e.g., Unauthenticated) can be defined here
      Support: 'support/',
      CardDetailResolver: 'card/:card_uuid',
      ProfileResolver: 'profile/:profile_uuid',
      WorldDetailResolver: 'world/:world_uuid',
    },
  },
};




const commonScreenOptions = {
  headerBackTitleVisible: false,
  headerStyle: {
    backgroundColor: brandColors.beige, // Set to brand beige
  },
  headerTintColor: brandColors.gray, // Set text color to brand grey
  headerTitleStyle: {
    fontWeight: 'bold',
    fontFamily: 'Barlow-Regular',
  },
  cardStyle: {
    backgroundColor: theme.colors.beige,
  },
  // headerTitleAlign: 'left',
  headerTitleContainerStyle: {
    // right: , // Adjust this value as needed to reduce the margin
    // top: 5,
  },
  // headerTitle: () => <SvgFabylHeader style={{ transform: [{ scale: 0.46 }] }}/>,
  headerTitle: () => renderHeader(),
  headerTitleAlign: 'center',
};


const addCommonScreens = (Stack) => {

  const notificationOptions = {
    headerShown: true,
    headerTitle: 'NOTIFICATIONS',
    headerTitleStyle: {
      fontFamily: theme.fonts.family.medium,
      fontSize: theme.fonts.sizes.xlarge,
    },
  };

  const worldChooseOptions = {
    headerShown: true,
    headerTitle: 'CHOOSE WORLD',
    headerTitleStyle: {
      fontFamily: theme.fonts.family.bold,
      fontSize: theme.fonts.sizes.large,
    },
    presentation: 'modal',
  }

  return (
    <>
      <Stack.Screen
        name="CardDetail"
        component={CardDetailScreen}
        options={{headerShown: true}}
      />
      <Stack.Screen
        name="ExpandedInput"
        component={ExpandedInputScreen}
        options={{headerShown: true, presentation: "modal"}}
      />
      <Stack.Screen
        name="UserWorldsList"
        component={UserWorldsListScreen}
        options={worldChooseOptions}
      />
      <Stack.Screen
        name="WorldDetail"
        component={WorldDetailScreen}
        options={{headerShown: true}}
      />
      <Stack.Screen
        name="World"
        component={WorldScreen}
        options={{headerShown: true, presentation: 'modal'}}
      />
      <Stack.Screen
        name="WorldCollaborators"
        component={WorldCollaboratorsScreen}
        options={{headerShown: true}}
      />
      <Stack.Screen name="WorldSettings" component={WorldSettingsScreen} />
      <Stack.Screen
        name="CardDeveloperDetails"
        component={CardDeveloperDetails}
        options={{headerShown: true}}
      />
      <Stack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={notificationOptions}
      />
      <Stack.Screen
        name="NotificationSettings"
        component={NotificationSettingsScreen}
        options={notificationOptions}
      />
      <Stack.Screen
        name="FriendRequests"
        component={FriendRequestsScreen}
        options={{
          ...notificationOptions,
          headerTitle: 'FRIEND REQUESTS'
        }}
      />
      <Stack.Screen
        name="UserProfile"
        component={PublicProfile}
        options={{
          headerShown: true,
          headerTitle: '',
          headerBackTitle: null,
          headerBackTitleVisible: false
        }}
      />
      <Stack.Screen
        name="Search"
        component={SearchScreen}
        options={{headerShown: true}}
      />
      {/* Add other common screens here */}
    </>
  );
};


function HomeStackNavigator() {
  const screenOptions = {
    ...commonScreenOptions,
    headerStyle: {
      ...commonScreenOptions.headerStyle,
    },
    cardStyle: {
      ...commonScreenOptions.cardStyle,
    }
  };

  return (
    <HomeStack.Navigator screenOptions={screenOptions}>
      <HomeStack.Screen name="HomeScreen" component={HomeScreen} />
      <HomeStack.Screen name="AllCards" component={AllCardsScreen} />
      {addCommonScreens(HomeStack)}
      {/* Add other screens related to Home here */}
    </HomeStack.Navigator>
  );
}


function CreateStackNavigator() {

  const screenOptions = {
    ...commonScreenOptions,
    headerTitle: '',
  };

  return (
    <CreateStack.Navigator screenOptions={commonScreenOptions}>
      <CreateStack.Screen
        name="CreateScreen"
        component={CreateScreen}
        options={screenOptions}
      />
      {addCommonScreens(CreateStack)}
    </CreateStack.Navigator>
  );
}


function ProfileStackNavigator() {
  return (
    <ProfileStack.Navigator screenOptions={commonScreenOptions}>
      <ProfileStack.Screen name="PrivateProfileScreen" component={PrivateProfileScreen} />
      <ProfileStack.Screen name="FriendsSearch" component={FriendsSearchScreen} options={{ headerShown: true, ...commonScreenOptions }}/>
      <ProfileStack.Screen name="BlockedUsersSearch" component={BlockedUsersSearchScreen} options={{ headerShown: true, ...commonScreenOptions }}/>
      <ProfileStack.Screen name="UserSearch" component={UserSearchScreen} options={
        {
          headerShown: true,
          headerStyle: {
            backgroundColor: brandColors.beige,
          },
        }}
      />
      {addCommonScreens(ProfileStack)}
      {/* Add other screens related to Profile here */}
    </ProfileStack.Navigator>
  );
}

// Drawer Nav on web needs its dedicated stack for search.
function SearchStackNavigator() {

  const screenOptions = {
    ...commonScreenOptions,
    headerTitle: '',
  };

  return (
    <SearchStack.Navigator initialRouteName="SearchScreen" screenOptions={screenOptions}>
      <SearchStack.Screen name="SearchScreen" component={SearchScreen} />
      <SearchStack.Screen name="UserProfile" component={PublicProfile} />
      <SearchStack.Screen name="CardDetail" component={CardDetailScreen} />
    </SearchStack.Navigator>
  );
}

function NotificationsStackNavigator() {

  const screenOptions = {
    ...commonScreenOptions,
    headerTitle: '',
  };

  return (
    <NotificationsStack.Navigator screenOptions={commonScreenOptions}>
      <Stack.Screen name="Notifications" component={NotificationsScreen} />
      <Stack.Screen name="NotificationSettings" component={NotificationSettingsScreen} />
      <Stack.Screen name="FriendRequests" component={FriendRequestsScreen} />
      <Stack.Screen name="CardDetail" component={CardDetailScreen} />
      <Stack.Screen name="World" component={WorldScreen} />
      <Stack.Screen name="WorldCollaborators" component={WorldCollaboratorsScreen} />
      <Stack.Screen name="UserProfile" component={PublicProfile} />
    </NotificationsStack.Navigator>
  );
}


function TabNavigator() {

  const insets = useSafeAreaInsets();

  const customTabPress = (navigation, routeName, defaultRoute) => {
    if (true) { // Platform.OS === 'web'
      // Custom navigation logic for web
      navigation.reset({
        index: 0,
        routes: [{ name: routeName, state: { routes: [{ name: defaultRoute }] } }],
      });
    } else {
      // Default behavior for non-web platforms
      navigation.navigate(routeName);
    }
  };

  const iconSize = 40;

  return (
    <Tab.Navigator
      initialRouteName={'Create'}
      screenOptions={({ route, navigation }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let IconComponent;
          if (route.name === 'Home') {
            IconComponent = focused ? SvgHomeWhite : SvgHome;
          } else if (route.name === 'Create') {
            IconComponent = focused ? SvgGenerateWhite : SvgGenerate;
          } else if (route.name === 'Profile') {
            IconComponent = focused ? SvgProfileWhite : SvgProfile;
          }
          return (
            <IconComponent iconSize={iconSize}/>
          );
        },
        tabBarLabel: '',
        tabBarActiveTintColor: theme.colors.yellowPrimary,
        tabBarInactiveTintColor: 'gray',
        headerShown: false,
        tabBarStyle: {
          backgroundColor: brandColors.gray,
          height: 60,
          paddingBottom: 0,
          // paddingTop: 15,
        },
        headerLeft: () => <HeaderIcon style={{ marginLeft: 12 }} />,
        // Adding listener to customize tab press behavior
        tabBarButton: (props) => (
          <TouchableOpacity
            {...props}
            hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
            onPress={() => customTabPress(navigation, route.name, `${route.name}Screen`)}
          />
        ),
      })}
    >
      <Tab.Screen name="Home" component={HomeStackNavigator} />
      <Tab.Screen name="Create" component={CreateStackNavigator} />
      <Tab.Screen name="Profile" component={ProfileStackNavigator} />
    </Tab.Navigator>
  );
}



function DrawerNavigator() {
  const isWideScreen = useIsWideScreen();

  const drawerScreenOptions = {
    drawerType: isWideScreen ? 'permanent' : 'front',
    drawerStyle: {
      backgroundColor: brandColors.gray,
      width: theme.layout.drawerWidth,
    },
    drawerLabelStyle: {
      fontFamily: theme.fonts.family.medium,
      fontSize: theme.fonts.sizes.large,
      color: theme.colors.white
    },
    headerShown: false,
    drawerActiveTintColor: theme.colors.gray,
    headerLeft: () => null,
    headerStyle: {
      backgroundColor: theme.colors.beige,
    },
    headerTitleAlign: "center",
  }

  const notificationOptions = {
    headerShown: true,
    headerTitle: 'NOTIFICATIONS',
    headerTitleStyle: {
      fontFamily: theme.fonts.family.medium,
      fontSize: theme.fonts.scaledSizes.xlarge,
    },
  };

  const settingsOptions = {
    headerShown: true,
    headerTitle: 'SETTINGS',
    headerTitleStyle: {
      fontFamily: theme.fonts.family.medium,
      fontSize: theme.fonts.scaledSizes.xlarge,
    },
  };

  const worldChooseOptions = {
    headerShown: true,
    headerTitle: 'CHOOSE WORLD',
    headerTitleStyle: {
      fontFamily: theme.fonts.family.medium,
      fontSize: theme.fonts.scaledSizes.xlarge,
    },
  };


  return (
    <Drawer.Navigator
      screenOptions={drawerScreenOptions}
      initialRouteName="Create"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <Drawer.Screen name="Home" component={HomeStackNavigator} />
      <Drawer.Screen name="Create" component={CreateStackNavigator} />
      <Drawer.Screen name="Profile" component={ProfileStackNavigator} />
      <Drawer.Screen name="Notifications" component={NotificationsStackNavigator} options={notificationOptions}/>
      <Drawer.Screen name="NotificationSettings" component={NotificationSettingsScreen} options={notificationOptions}/>
      <Drawer.Screen name="Search" component={SearchStackNavigator} />
      <Drawer.Screen name="UpgradeScreen" component={UpgradeScreen}/>
      <Drawer.Screen name="Settings" component={SettingsScreen} options={settingsOptions} />
      <Drawer.Screen name="RiffCard" component={RiffCardScreen} />

      {/*<CreateStack.Screen name="ExpandedInput" component={ExpandedInputScreen} />*/}
      <Drawer.Screen name="ExpandedInput" component={ExpandedInputScreen} options={{headerShown: true}}/>
      <Drawer.Screen name="WorldDetail" component={WorldDetailScreen} options={{headerShown: true}}/>
      <Drawer.Screen name="UserWorldsList" component={UserWorldsListScreen} options={worldChooseOptions}/>
      <Drawer.Screen name="CreateWorld" component={CreateWorldScreen} options={{headerShown: true}}/>
      <Drawer.Screen name="FabylCreation" component={FabylCreationScreen} />
      <Drawer.Screen name="WorldCreation" component={WorldCreationScreen}/>

    </Drawer.Navigator>
  );
}


function AuthenticatedStack() {
  const isWideScreen = useIsWideScreen();
  const NavigatorComponent = isWideScreen ? DrawerNavigator : TabNavigator;
  const { profileData, isLoading, error } = useProfile();
  const { pushToken, setPushToken } = usePushToken();
  const navigation = useNavigation();


  const checkPermissionsAndRegister = async () => {
    if (!isLoading && !error && profileData) {
      if (!profileData.tutorial_seen) {
        navigation.navigate('Tutorial');
      }
      const token = await registerForPushNotificationsAsync();
      if (token) {
        setPushToken(token); // Use context method to set token
      }
    }
  };

  useEffect(() => {
    checkPermissionsAndRegister();

    const subscription = Notifications.addNotificationResponseReceivedListener(response => {
      // Handle notification response
      console.log(response);
    });

    const appStateListener = AppState.addEventListener('change', state => {
      if (state === 'active') {
        checkPermissionsAndRegister();
      }
    });

    return () => {
      Notifications.removeNotificationSubscription(subscription);
      appStateListener.remove();
    };
  }, [profileData, isLoading, error, navigation, setPushToken]);

  const screenOptions = {
    ...commonScreenOptions,
    headerTitle: 'SETTINGS',
    headerTitleStyle: {
      fontFamily: theme.fonts.family.medium,
      fontSize: theme.fonts.sizes.xlarge,
    },
    headerShown: true,
  };

  const chooseWorldOptions = {
    ...commonScreenOptions,
    headerTitle: 'CHOOSE WORLD',
    headerTitleStyle: {
      fontFamily: theme.fonts.family.medium,
      fontSize: theme.fonts.sizes.xlarge,
    },
    headerShown: true,
  };

  const upgradeScreenOptions = {
    ...commonScreenOptions,
    // headerTitle: 'UPGRADE',
    headerTitleStyle: {
      fontFamily: theme.fonts.family.medium,
      fontSize: theme.fonts.sizes.xlarge,
    },
    headerShown: true,
  };


  const fullCardScreenOptions = {
    headerBackTitleVisible: false,
    headerStyle: {
      backgroundColor: brandColors.gray,
    },
    headerTintColor: brandColors.white,
    cardStyle: {
      backgroundColor: theme.colors.beige,
    },
    headerTitle: () => <SvgFabylHeader style={{transform: [{scale: 0.46}]}} fillColor={theme.colors.lightgray}/>,
    headerTitleAlign: "center",
    headerTitleStyle: {
      fontFamily: theme.fonts.family.medium,
      fontSize: theme.fonts.sizes.xlarge,
    },
    headerShown: false, // Hide the header
  };

  return (
    <AuthStack.Navigator screenOptions={{ headerShown: false, presentation: "modal" }}>
      <AuthStack.Screen name="Tabs" component={NavigatorComponent} />
      <AuthStack.Screen name="FullscreenDetail" component={FullscreenDetailScreen}/>
      <AuthStack.Screen name="Settings" component={SettingsScreen} options={screenOptions}/>
      <AuthStack.Screen name="FriendsSearch" component={FriendsSearchScreen} options={{ headerShown: true, ...commonScreenOptions }}/>
      <AuthStack.Screen name="BlockedUsersSearch" component={BlockedUsersSearchScreen} options={{ headerShown: true, ...commonScreenOptions }}/>
      <AuthStack.Screen name="UserSearch" component={UserSearchScreen} options={{
          headerShown: true,
          ...commonScreenOptions,
      }}/>
      <AuthStack.Screen name="UpdateEmail" component={UpdateEmailScreen} options={screenOptions}/>
      <AuthStack.Screen name="UpdatePhone" component={UpdatePhoneScreen} options={screenOptions}/>
      <AuthStack.Screen name="Search" component={SearchScreen} options={{ headerShown: true }}/>
      <AuthStack.Screen name="RiffCard" component={RiffCardScreen} />
      <AuthStack.Screen name="ExpandedInput" component={ExpandedInputScreen} />
      <AuthStack.Screen name="WorldDetail" component={WorldDetailScreen} options={fullCardScreenOptions}/>
      <AuthStack.Screen name="UserWorldsList" component={UserWorldsListScreen} options={chooseWorldOptions}/>
      <AuthStack.Screen name="CreateWorld" component={CreateWorldScreen} options={screenOptions}/>
      <AuthStack.Screen name="FabylCreation" component={FabylCreationScreen} />
      <AuthStack.Screen name="WorldCreation" component={WorldCreationScreen} options={screenOptions}/>
      <AuthStack.Screen name="ChangePassword" component={ChangePasswordScreen} options={screenOptions}/>
      <AuthStack.Screen name="YourWorlds" component={YourWorldsScreen} options={{
        ...screenOptions,
        headerTitle: "YOUR WORLDS"
      }}
      />
      <AuthStack.Screen name="FriendSharedWorlds" component={FriendSharedWorldsScreen} options={{...screenOptions, headerTitle: "SHARED WORLDS"}}/>
      <AuthStack.Screen name="MeetTheTeam" component={MeetTheTeamScreen} options={screenOptions}/>
      <AuthStack.Screen name="Tutorial" component={TutorialScreen} options={{headerShown: false}}/>
      <AuthStack.Screen name="UpgradeScreen" component={UpgradeScreen} options={upgradeScreenOptions}/>
      <AuthStack.Screen name="DeactivateAccount" component={DeactivateAccountScreen} options={screenOptions}/>
    </AuthStack.Navigator>
  );
}


const renderHeader = () => {
  return (
     <View>
      <SvgFabylHeader
        style={{
          transform: [{scale: 0.46}],
          alignSelf: 'center',
          height: 'auto',
          width: '100%',
      }}
        fillColor={theme.colors.black}
      />
    </View>
  );
}

// Unauthenticated stack
function UnauthenticatedStack() {

  const screenOptions = {
    headerBackTitleVisible: false,
    headerStyle: {
      backgroundColor: brandColors.beige,
    },
    headerTitleAlign: "center",
    headerTintColor: brandColors.black,
    cardStyle: {
      backgroundColor: theme.colors.beige,
    },
    headerTitle: renderHeader,
    headerTitleStyle: {
      fontFamily: theme.fonts.family.medium,
      fontSize: theme.fonts.sizes.xlarge,
    },
    headerShown: true, // Hide the header
  };

  return (
    <UnAuthStack.Navigator screenOptions={{ headerShown: false }} initialRouteName={Platform.OS === 'web' ? 'LandingPage' : 'Login'}>
      {Platform.OS === 'web' && (
        <UnAuthStack.Screen name="LandingPage" component={LandingPage}/>
      )}
      <UnAuthStack.Screen name="Login" component={LoginScreen} />
      <UnAuthStack.Screen name="SignUp" options={screenOptions}>
        {(props) => <SignUpScreen {...props}/>}
      </UnAuthStack.Screen>
      <UnAuthStack.Screen name="Tutorial" component={TutorialScreen} options={{headerShown: false}}/>
      <UnAuthStack.Screen name={"EmailSignUpScreen"} component={EmailSignUpScreen} options={screenOptions}/>
    </UnAuthStack.Navigator>

  );
}


function RootNavigator() {
  const {
    isAuthenticated,
    isLoading,
    mustSetUsername,
    isPasswordChangeRequired
  } = useContext(AuthContext);
  // Now it's used within the provider

  if (isLoading) {
    return null; // Or a loading indicator until the auth state is resolved
  }

  const screenOptions = {
    ...commonScreenOptions,
    headerTitle: 'SETTINGS',
    headerTitleStyle: {
      fontFamily: theme.fonts.family.medium,
      fontSize: theme.fonts.sizes.xlarge,
    },
    headerShown: true,
  };


  return (
    <RootSiblingParent>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {!isAuthenticated && isPasswordChangeRequired && (
          // If the user is needs to complete a new password (and therefore should not be authenticated)
          <Stack.Screen name="CompleteNewPasswordScreen" component={CompleteNewPasswordScreen} />
        )}
        {isAuthenticated && mustSetUsername && (
          // If the user is authenticated, doesn't need a password change but needs to set a username
          <Stack.Screen name="SetUsernameScreen" component={SetUsernameScreen} />
        )}
        {isAuthenticated && !mustSetUsername && (
          // If the user is authenticated and doesn't need to set a username nor complete a new password
          <Stack.Screen name="Authenticated" component={AuthenticatedStack} />
        )}
        {!isAuthenticated && !isPasswordChangeRequired && (
          // If the user is not authenticated
          <Stack.Screen name="Unauthenticated" component={UnauthenticatedStack} />
        )}
        <Stack.Screen name="OtpScreen" component={OtpScreen} options={screenOptions} />
        <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={screenOptions}/>
        <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} options={screenOptions}/>
        <Stack.Screen name="CardDetailResolver" component={CardDetailResolver} />
        <Stack.Screen name="ProfileResolver" component={ProfileResolver} />
        <Stack.Screen name="WorldDetailResolver" component={WorldDetailResolver} />
        <Stack.Screen name="Support" component={SupportScreen} options={screenOptions}/>
        <Stack.Screen
          name="PublicCardDetail"
          component={CardDetailScreen}
          options={{
            headerShown: true,
            presentation: 'modal',
            cardStyle: {
              backgroundColor: theme.colors.beige,
            },
            headerStyle: {
              backgroundColor: theme.colors.beige, // Set your desired color
            },
            headerTitle: () => renderHeader(),
            headerTitleAlign: 'center', // Ensure title is centered
        }}
        />
        <Stack.Screen
          name="PublicWorldDetail"
          component={WorldDetailScreen}
          options={{
            presentation: 'modal',
            cardStyle: {
              backgroundColor: theme.colors.beige,
            },
            headerStyle: {
              backgroundColor: theme.colors.beige, // Set your desired color
            },
            headerTitle: () => renderHeader(),
            headerTitleAlign: 'center', // Ensure title is centered
          }}
        />
        <Stack.Screen
          name="World"
          component={WorldScreen}
          options={{headerShown: true, presentation: 'modal'}}
        />
        <Stack.Screen name="ExpandedInput" component={ExpandedInputScreen} options={{headerShown: true}}/>
        <Stack.Screen name="FabylCreation" component={FabylCreationScreen} />
        <Stack.Screen name="WorldCreation" component={WorldCreationScreen} options={screenOptions}/>
        <Stack.Screen name="WorldDetail" component={WorldDetailScreen} options={{headerShown: true}}/>
        <Stack.Screen name="UserWorldsList" component={UserWorldsListScreen} options={{headerShown: true}}/>
        <Stack.Screen
          name="PublicUserProfile"
          component={UnauthPublicProfile}
          options={{
            cardStyle: {
              backgroundColor: theme.colors.beige,
            },
            headerStyle: {
              backgroundColor: theme.colors.beige, // Set your desired color
            },
            headerShown: true,
            headerTitle: () => renderHeader(),
            headerTitleAlign: 'center',
            headerBackTitle: null,
            headerBackTitleVisible: false
          }}
        />
      </Stack.Navigator>
    </RootSiblingParent>
  );
}


const handleNotificationResponse = (response) => {
  const data = response.notification.request.content.data;
  console.log(`Opening the link with data: ${JSON.stringify(data)}`);

  // Mark the notification as read without waiting for it to complete
  if (data.notification_id) {
    markNotificationAsRead(data.notification_id).catch((error) => {
      console.error(`Failed to mark notification as read: ${error}`);
    });
  }

  if (data.type === 'cardDetail' && data.card_uuid) {
    // Use Linking.makeUrl() for compatibility with Expo Go
    const url = Linking.createURL(`/card/${data.card_uuid}`);
    Linking.openURL(url); // Open the deep link URL to navigate within the Expo Go app
  }
  else if (data.type === 'profile' && data.profile_uuid) {
    const url = Linking.createURL(`/profile/${data.profile_uuid}`);
    Linking.openURL(url);
  }
};


SplashScreen.preventAutoHideAsync();

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false);

  useAssets([
    require('./assets/images/characters/Frankenpug_medium.png'),
    require('./assets/images/characters/Vorkov_medium.png'),
    require('./assets/images/characters/SpaceChef_medium.png'),
    require('./assets/images/characters/Robot_medium.png'),
    require('./assets/images/characters/SteampunkHillbilly_medium.png')
  ]);
  // We don't wait for image assets, only for fonts. But we do start the loading process here.

  useEffect(() => {
    async function prepare() {
      try {
        // Keep the splash screen visible while we fetch resources
        // Load fonts
        await Font.loadAsync({
          'Barlow-ExtraLight': require('./assets/fonts/Barlow/Barlow-ExtraLight.ttf'),
          'Barlow-Light': require('./assets/fonts/Barlow/Barlow-Light.ttf'),
          'Barlow-Black': require('./assets/fonts/Barlow/Barlow-Black.ttf'),
          'Barlow-Regular': require('./assets/fonts/Barlow/Barlow-Regular.ttf'),
          'Barlow-Medium': require('./assets/fonts/Barlow/Barlow-Medium.ttf'),
          'Barlow-Bold': require('./assets/fonts/Barlow/Barlow-Bold.ttf'),
          'Barlow-ExtraBold': require('./assets/fonts/Barlow/Barlow-ExtraBold.ttf'),
          'Barlow-Italic': require('./assets/fonts/Barlow/Barlow-Italic.ttf'),
        });

        // Prefetch the suggested titles
        await queryClient.prefetchQuery('suggestedTitles', fetchSuggestedTitles);
        await queryClient.prefetchInfiniteQuery(
          ['cards', API_ENDPOINTS.RANDOM_CARDS],
          fetchInitialRandomCards
        );


        console.log(`Loaded fonts`)
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        console.log(`App is ready`)
        setAppIsReady(true);
      }
    }

    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      console.log(`Hiding splash screen`)
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  useEffect(() => {
    const subscription = Notifications.addNotificationResponseReceivedListener(handleNotificationResponse);
    return () => Notifications.removeNotificationSubscription(subscription);
  }, []);

  // this must be placed right before the return statement
  // otherwise the number of hooks will be inconsistent and we will have errors.
  if (!appIsReady) {
    return null;
  }

  return (
    <PushTokenProvider>
      <StripeProvider publishableKey={process.env.EXPO_PUBLIC_STRIPE_PUBLISHABLE_KEY}>
        <QueryClientProvider client={queryClient}>
          <AudioPlayerProvider>
            <AutoplayProvider>
              <AuthProvider>
                <ProfileProvider>
                  <ThemeProvider theme={theme}>
                    <NavigationContainer linking={linking} style={globalStyles.body} onReady={onLayoutRootView}>
                      <RootNavigator/>
                    </NavigationContainer>
                  </ThemeProvider>
                </ProfileProvider>
              </AuthProvider>
            </AutoplayProvider>
          </AudioPlayerProvider>
        </QueryClientProvider>
      </StripeProvider>
    </PushTokenProvider>
  );
}

const styles = StyleSheet.create({
  headerIconContainer: {
    // Style your header icon container
    alignItems: 'center',
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#cccccc',
  },
  headerIcon: {
    // Style your header icon
  },
});
