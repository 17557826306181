import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import SvgFabylHeader from "../assets/images/FabylHeader";
import theme from "../styles/theme";
import HorizontalMiniCardGallery from "../components/HorizontalMiniCardGallery";
import globalStyles from "../styles/globalStyles";
import SearchButton from "../components/SearchButton";
import NotificationsButton from "../components/NotificationsButton";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import useCards from "../hooks/useCards";
import API_ENDPOINTS from '../apiEndpoints';
import {useIsFocused} from "@react-navigation/native";
import {getScrollPosition, setScrollPosition} from '../utils/scrollPositionManager';
import CreateButton from "../components/buttons/CreateButton";
import HorizontalMiniCardGallerySkeleton
  from "../components/skeletons/HorizontalMiniCardSkeletonGallery";
import useWorlds from "../hooks/useWorlds";
import TinyWorldComponent from "../components/TinyWorldComponent";


function CreateScreen({ navigation }) {
  const isLargeScreen = useIsLargeScreen();
  const scrollPositionRef = useRef(null);
  const [isGalleryReady, setIsGalleryReady] = useState(false);
  const isFocused = useIsFocused();
  const { getFeaturedWorld } = useWorlds();
  const { data: featuredWorld, isLoading, error } = getFeaturedWorld();

  // Load the saved scroll position when the screen focuses
  useEffect(() => {
    if (isFocused) {
      const savedPosition = getScrollPosition('CreateScreen');
      scrollPositionRef.current = savedPosition;
      setIsGalleryReady(true);
    }
  }, [isFocused]);

  const handleScrollPositionChange = useCallback((position) => {
    scrollPositionRef.current = position;
    setScrollPosition('CreateScreen', position);
  }, []);
  // console.log(`rendering with scroll position: ${scrollPosition}`)

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: !isLargeScreen ? () => (
        <View style={globalStyles.headerRightContainer}>
          <NotificationsButton navigation={navigation}/>
          <SearchButton
            navigation={navigation}
            targetScreen='Search'
            facetKey='myCards'
          />
        </View>
      ) : null,
      headerShown: !isLargeScreen,
    });
  }, [navigation, isLargeScreen]);


  const {
    cards,
    nextPageURL,
    loadingCards,
    fetchMoreCards,
    handleRefresh,
  } = useCards(API_ENDPOINTS.RANDOM_CARDS);

  const handleCreatePress = () => {
    navigation.navigate('ExpandedInput');
  };
  console.log(`render with initial scroll position: ${scrollPositionRef.current}`)

  return (
    <View style={styles.mainContainer}>
      <View style={styles.headerSection}>
        <View style={styles.fabylHeaderContainer}>
          <SvgFabylHeader />
          <View style={styles.brandContainer}>
            <Text style={globalStyles.brandPhrase}>weave whimsical worlds</Text>
          </View>
        </View>
      </View>
      <View style={styles.gallerySection}>
      {isFocused && scrollPositionRef.current !== null && (!loadingCards || cards) ? (
        <HorizontalMiniCardGallery
          cards={cards}
          nextPageURL={nextPageURL}
          loadMore={fetchMoreCards}
          loading={loadingCards}
          initialScrollPosition={scrollPositionRef.current}
          onScrollPositionChange={handleScrollPositionChange}
        />
      ) : (
        <HorizontalMiniCardGallerySkeleton />
      )}
      </View>
      <View style={styles.featuredWorldSection}>
        <View style={styles.featuredWorldTitleContainer}>
          <Ionicons name="planet-outline" size={24} color={theme.colors.yellowSecondary} />
          <Text style={styles.featuredWorldTitle}>Featured World</Text>
        </View>
        <TinyWorldComponent world={featuredWorld}/>
      </View>
      <View
        style={[
          styles.createSection,
          isLargeScreen ? { justifyContent: 'flex-start' } : null,
        ]}
      >
        <CreateButton
          onPress={handleCreatePress}
          text="NAME YOUR FABYL..."
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  headerSection: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  createSection: {
    flex: 1,
    alignSelf: 'center',
    maxWidth: 600,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gallerySection: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  fabylHeaderContainer: {
    alignSelf: 'center',
    alignItems: 'center',
  },
  brandContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  createButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center', // Changed to center the text
    backgroundColor: theme.colors.white,
    borderRadius: theme.borderRadius.large,
    paddingHorizontal: 20,
    paddingVertical: 15,
    width: '90%',
    maxWidth: 400,
    position: 'relative', // Ensure the button container is a positioning context
  },
  createButtonText: {
    color: theme.colors.redPrimary,
    fontSize: theme.fonts.sizes.large,
    fontWeight: 'bold',
    textAlign: 'center', // Center the text
    flex: 1, // Allow text to take full width
  },
  plusIcon: {
    position: 'absolute', // Use absolute positioning
    left: 20, // Adjust as needed for padding
  },
  featuredWorldSection: {
    marginVertical: 20,
    alignItems: 'center',
  },
  featuredWorldTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  featuredWorldTitle: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.yellowSecondary,
    textTransform: 'uppercase',
    marginLeft: 8, // Add some space between the icon and text
  },
});

export default React.memo(CreateScreen);

