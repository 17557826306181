import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Text, SafeAreaView } from 'react-native';
import BouncingSpurs from "../components/BouncingSpurs";
import WhimsicalMessages from "../components/WhimsicalMessages";
import { checkTaskData, fetchWorld } from "../utils/api/cardsService";
import { useQueryClient } from "react-query";
import { useProfile } from "../contexts/ProfileContext";
import alert from "../utils/alert";
import theme from '../styles/theme';
import CreatingWorldComponent from "../components/CreatingWorldComponent";

const WorldCreationScreen = ({ route, navigation }) => {
  const { worldName, worldDescription, task_id, uuid } = route.params;
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const pollIntervalRef = useRef(null);
  const queryClient = useQueryClient();
  const { refetchProfileData } = useProfile();


  useEffect(() => {
    pollCreationProgress();
    return () => {
      if (pollIntervalRef.current) clearInterval(pollIntervalRef.current);
    };
  }, []);

  const pollCreationProgress = () => {
    pollIntervalRef.current = setInterval(async () => {
      try {
        const taskData = await checkTaskData(task_id);
        if (taskData.status === 'SUCCESS') {
          clearInterval(pollIntervalRef.current);
          const prefetchedData = await fetchWorld(uuid);
          queryClient.invalidateQueries('worlds');
          navigation.navigate('Authenticated', {
            screen: 'Tabs',
            params: {
              screen: 'Home',
              params: {
                screen: 'WorldDetail',
                params: { world_uuid: uuid },
              },
            },
          });
          refetchProfileData();
        } else if (taskData.status === 'FAILURE') {
          throw new Error("World creation failed");
        } else if (taskData.status === 'PROGRESS') {
          setProgress(taskData.meta.total);
        }
      } catch (error) {
        handleError(error);
      }
    }, 2000);
  };

  const handleError = (error) => {
    clearInterval(pollIntervalRef.current);
    setIsLoading(false);
    alert("Error", error.message || "An error occurred. Please try again.");
    navigation.goBack();
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.title}>creating your world...</Text>
        <CreatingWorldComponent worldName={worldName} />
        <BouncingSpurs isAnimating={isLoading} progress={progress} />
        <WhimsicalMessages isAnimating={isLoading} progress={progress} />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.beige,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.gray,
    marginBottom: theme.spacings.medium,
    fontFamily: theme.fonts.family.regular,
  },
  worldName: {
    fontSize: theme.fonts.sizes.xlarge,
    color: theme.colors.yellowPrimary,
    marginBottom: theme.spacings.large,
    fontFamily: theme.fonts.family.bold,
  },
});

export default WorldCreationScreen;
