import React from 'react';
import { TouchableOpacity } from 'react-native';
import SvgFabylHeader from '../assets/images/FabylHeader';
import { Linking } from 'react-native';
import theme from '../styles/theme';
import {useNavigation} from "@react-navigation/native";

const HeaderTitle = ({ isAuthenticated }) => {
  const navigation = useNavigation();

  const handleFabylHeaderPress = () => {
    console.log(`trying to navigate via header`)
    if (isAuthenticated) {
      navigation.navigate('Home');
    } else {
      navigation.navigate('Unauthenticated', {
        screen: 'LandingPage',
      });

    }
  };

  if (isAuthenticated) {
    return null;
  }

  return (
    <TouchableOpacity onPress={handleFabylHeaderPress}>
      <SvgFabylHeader
        style={{
          transform: [{ scale: 0.46 }],
          alignSelf: 'center',
          height: 'auto',
          width: '100%',
        }}
        fillColor={theme.colors.black}
      />
    </TouchableOpacity>
  );
};

export default HeaderTitle;
