import {useInfiniteQuery, useQuery, useQueryClient} from 'react-query';
import {
  fetchWorlds,
  fetchWorld,
  fetchFeaturedWorld,
  fetchLandingPageFeaturedWorlds
} from "../utils/api/cardsService";


function useWorlds(endpoint) {
  const queryClient = useQueryClient();

  const fetchFunction = ({ pageParam = endpoint }) => {
    // Only perform the fetch if endpoint is not null
    if (endpoint) {
      return fetchWorlds(pageParam); // Use fetchWorlds instead of fetchCards
    } else {
      // Return a resolved promise with an empty structure to maintain consistency
      return Promise.resolve({ worlds: [], nextPageURL: null });
    }
  };

  const getWorld = (worldId) => {
    console.log(worldId);
    return useQuery(
      ['world', worldId],
      () => fetchWorld(worldId),
      {
        initialData: () => {
          const worldsData = queryClient.getQueryData(['worlds', endpoint]);
          return worldsData?.pages?.flatMap(page => page.worlds).find(world => world.uuid === worldId);
        },
        staleTime: 5 * 60 * 1000,
        cacheTime: 10 * 60 * 1000,
      }
    );
  };

  const getFeaturedWorld = () => {
    return useQuery(
      'featuredWorld',
      fetchFeaturedWorld,
      {
        staleTime: 5 * 60 * 1000, // 5 minutes
        cacheTime: 10 * 60 * 1000, // 10 minutes
      }
    );
  };

  const getLandingPageFeaturedWorlds = (limit = 12) => {
    return useQuery(
      ['landingPageFeaturedWorlds', limit],
      () => fetchLandingPageFeaturedWorlds(limit),
      {
        staleTime: 5 * 60 * 1000, // 5 minutes
        cacheTime: 10 * 60 * 1000, // 10 minutes
      }
    );
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
    isRefetching,
  } = useInfiniteQuery(
    ['worlds', endpoint], // Change the query key to "worlds"
    fetchFunction,
    {
      getNextPageParam: (lastPage) => lastPage.nextPageURL || undefined,
      enabled: !!endpoint, // Only run the query if endpoint is not null
      // refetchOnWindowFocus: false, // Adjust based on your preference
      refetchOnWindowFocus: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
    }
  );

  const worlds = data?.pages?.flatMap(page => page.worlds) ?? [];
  const loadingWorlds = status === 'loading' || isFetching || isRefetching;

  return {
    worlds,
    nextPageURL: data?.pages?.[data.pages.length - 1]?.nextPageURL,
    loadingWorlds: loadingWorlds,
    fetchMoreWorlds: () => {
      if (hasNextPage) fetchNextPage();
    },
    handleRefresh: refetch,
    isRefetching: isRefetching,
    getWorld,
    getFeaturedWorld,
    getLandingPageFeaturedWorlds,
  };
}

export default useWorlds;
