import React, {memo} from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Image } from "expo-image";
import theme from "../styles/theme";
import {useMinimalProfileData} from "../hooks/useMinimalProfileData";

const SkeletonImage = ({ username, size=50, borderColor = theme.colors.gray, borderWidth=StyleSheet.hairlineWidth }) => (
  <View style={[styles.skeletonThumbnail, { width: size, height: size, borderRadius: size / 2, borderColor, borderWidth: borderWidth }]}>
    <Text style={[styles.skeletonText, {fontSize: size / 2}]}>{username ? username.charAt(0).toUpperCase() : ''}</Text>
  </View>
);

const UserCircle = memo(({ profile_uuid, size = 50, borderColor = theme.colors.gray, borderWidth = StyleSheet.hairlineWidth }) => {
  const { data: user, isLoading, isError } = useMinimalProfileData(profile_uuid);

  // Return null if user doesn't exist
  if (!user) {
    return null;
  }

  return (
    <View>
      {user?.featured_card ? (
        <Image source={{ uri: user?.featured_card.thumbnail }} style={[styles.cardThumbnail,  { width: size, height: size, borderRadius: size / 2, borderColor: borderColor, borderWidth: borderWidth }]} />
      ) : (
        <SkeletonImage username={user?.preferred_username} size={size} borderColor={borderColor} borderWidth={borderWidth} />
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  cardThumbnail: {
    // borderWidth: StyleSheet.hairlineWidth,
    borderWidth: 1,
    borderColor: theme.colors.gray,
  },
  resultItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    color: theme.colors.bluePrimary,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.medium,
  },
  skeletonThumbnail: {
    backgroundColor: theme.colors.verylightgray,
    justifyContent: 'center',
    alignItems: 'center',
    // borderWidth: StyleSheet.hairlineWidth,
    borderWidth: StyleSheet.hairlineWidth,
  },
  skeletonText: {
    color: theme.colors.gray,
    fontWeight: 'bold',
  },
});

export default UserCircle;
