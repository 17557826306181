import React, { useCallback, memo } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from 'react-query';
import { fetchNextWorld } from '../utils/api/cardsService';
import theme from '../styles/theme';
import { Ionicons } from '@expo/vector-icons';
import { Image } from 'expo-image';

export const SIZE = 80;
const CHEVRON_SIZE = 50;

const MiniCardTile = memo(({ item, margin = 5 }) => {
    const imageUri = item?.thumbnail || item?.art;

    const placeholderStyle = {
        width: SIZE,
        height: SIZE,
        margin: margin,
        borderRadius: theme.borderRadius.small,
        backgroundColor: theme.colors.beige,
    };

    if (!imageUri) {
        return <View style={placeholderStyle} />;
    }

    return (
        <Image
            source={{ uri: imageUri }}
            style={{
                width: SIZE,
                height: SIZE,
                margin: margin,
                borderRadius: theme.borderRadius.small,
            }}
            placeholder={placeholderStyle}
            transition={300}
        />
    );
});

const WorldNavigationBar = memo(({ currentWorldUUID }) => {
    const navigation = useNavigation();

    const { data: prevWorld } = useQuery(
        ['prevWorld', currentWorldUUID],
        () => fetchNextWorld(currentWorldUUID, 'previous'),
        {
          enabled: !!currentWorldUUID,
          staleTime: 5 * 60 * 1000,  // 5 minutes
          cacheTime: 30 * 60 * 1000, // 30 minutes
        }
    );

    const { data: nextWorld } = useQuery(
        ['nextWorld', currentWorldUUID],
        () => fetchNextWorld(currentWorldUUID, 'next'),
        {
          enabled: !!currentWorldUUID,
          staleTime: 5 * 60 * 1000,  // 5 minutes
          cacheTime: 30 * 60 * 1000, // 30 minutes
        }
    );

    const navigateToWorld = useCallback((world) => {
        if (world && world.root_card) {
            navigation.replace('CardDetail', {
                card_uuid: world.root_card.uuid,
                world_uuid: world.uuid
            });
        }
    }, [navigation]);

    return (
        <View style={styles.navigationBar}>
            <TouchableOpacity
                onPress={() => navigateToWorld(prevWorld)}
                style={styles.navButtonContainer}
                disabled={!prevWorld}
            >
                <Ionicons
                    name="chevron-back"
                    size={CHEVRON_SIZE}
                    color={!prevWorld?.root_card ? theme.colors.beige : theme.colors.redPrimary}
                />
                <MiniCardTile item={prevWorld?.root_card} margin={2} />
            </TouchableOpacity>
            <TouchableOpacity
                onPress={() => navigateToWorld(nextWorld)}
                style={styles.navButtonContainer}
                disabled={!nextWorld}
            >
                <MiniCardTile item={nextWorld?.root_card} margin={2} />
                <Ionicons
                    name="chevron-forward"
                    size={CHEVRON_SIZE}
                    color={!nextWorld?.root_card ? theme.colors.beige : theme.colors.redPrimary}
                />
            </TouchableOpacity>
        </View>
    );
});

const styles = StyleSheet.create({
    navigationBar: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacings.small,
        zIndex: 10,
    },
    navButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export default memo(WorldNavigationBar);
