import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Alert,
  ScrollView,
  TouchableWithoutFeedback,
  Dimensions
} from 'react-native';
import {Image} from 'expo-image';
import {
  FontAwesome,
  Ionicons,
  MaterialIcons
} from '@expo/vector-icons';
import {useQueryClient} from 'react-query';
import {useNavigation} from "@react-navigation/native";
import {
  fetchCardByUUID,
} from "../utils/api/cardsService";
import theme, { moderateScaleSize, cardScaleSize, textScaleSize }  from "../styles/theme";
import SvgRiff from "../assets/images/Riff";
import {useFullscreen} from "../contexts/FullscreeenContext";
import SvgRiffLine from "../assets/images/RiffLine";
import SvgSpurWithFWhiteOnRed from "../assets/images/SpurWithFWhiteOnRed";
import SvgSiblingButtonLeft from "../assets/images/SiblingButtonLeft";
import SvgSiblingButtonRight from "../assets/images/SiblingButtonRight";
import SvgRiffLineLeft from "../assets/images/RiffLineLeft";
import SvgRiffLineRight from "../assets/images/RiffLineRight";
import SiblingNavigationButtons from "./SiblingNavigationButtons";
import MenuModal from "./modals/MenuModal";
import OptionsModal from "./modals/OptionsModal";
import {useProfile} from "../contexts/ProfileContext";
import CardDetailsModal from "./modals/CardDetailsModal";
import Animated, { useSharedValue, useAnimatedStyle, withSpring, runOnJS, withTiming, Easing } from 'react-native-reanimated';
import useCardActions from "../hooks/useCardActions";
import {useAudioPlayer} from "../contexts/AudioPlayerContext";
import { useAutoplay } from "../contexts/AutoplayContext";
import NormalImage from "./NormalImage";
import ZoomableImageModal from "./modals/ZoomableImageModal";
import UserCircle from "./UserCircle";
import {AuthContext} from "../contexts/AuthContext";
import LoginPromptModal from "./modals/LoginPromptModal";
import AddFriendToRiffModal from "./modals/AddFriendToRiffModal";
import { LinearGradient } from 'expo-linear-gradient';
import {useMinimalProfileData} from "../hooks/useMinimalProfileData";
import PlayPauseButton from './buttons/PlayPauseButton';  // Adjust the import path as necessary


export const baseWidth = 350; // Base width used by react-native-size-matters
export const baseHeight = 680; // Base height used by react-native-size-matters

const cardMarginVertical = theme.spacings.small;
const cardMarginHorizontal = theme.spacings.xxsmall;
export const cardWidth = baseWidth;
export const cardHeightOuter = baseHeight
export const cardHeight = cardWidth * 1.5;
const imageWidth = cardWidth - (2 * theme.spacings.small)


const truncateUsername = (username, truncateLength = 10) => {
  if (!username) {
    return ''; // Return an empty string if username is falsy
  }
  if (username.length > 10) {
    return `${username.substring(0, truncateLength)}...`; // Truncate and add ellipsis
  }
  return username; // Return the original username if it's 10 characters or less
};


export function CardSkeleton() {
  return (
    <View style={styles.cardContainer}>
      <View style={styles.Card}>
        <SvgSpurWithFWhiteOnRed style={styles.spur}/>
        <LinearGradient
          colors={[theme.colors.lightgray, "#585858", "#424242"]}
          style={styles.image}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
        />
        <View style={styles.contentPlaceholder} />
      </View>
      <SvgRiffLine style={styles.riffLine}/>
    </View>
  );
}

function Card({card, onImageTap, openModal, isViewable}) {
  const navigation = useNavigation();
  const { profileData } = useProfile();
  const { autoplay } = useAutoplay();
  const { isAuthenticated } = useContext(AuthContext);

  const { performLike, heartColor, shareCard, performBookmark, bookmarkColor } = useCardActions(card);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomMode, setZoomMode] = useState(false);

  const [showCreateComponent, setShowCreateComponent] = useState(false);
  const queryClient = useQueryClient();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoginModalVisible, setLoginModalVisible] = useState(false);

  const scale = useSharedValue(1);
  const scaleUpSize = 1.025;
  const pressReleased = useSharedValue(false);

  const { playTitleAndFlavor, stopSound, isPlaying, togglePlayPause, progress,  isLoading } = useAudioPlayer();
  const animatedWidth = useSharedValue(0);
  const [flavorFontSize, setFlavorFontSize] = useState(theme.fonts.sizes.medium);
  const { data: cardProfileData } = useMinimalProfileData(card?.creator_uuid);

  useEffect(() => {
    const calculateFlavorFontSize = (flavorText) => {
      const maxLength = 350; // Define the max length for smaller text size
      const minLength = 100; // Define the min length for larger text size

      // Define the minimum and maximum font sizes
      const minFontSize = theme.fonts.sizes.small; // Smallest font size
      const maxFontSize = theme.fonts.sizes.medium; // Largest font size

      if (flavorText.length > maxLength) {
        return minFontSize; // Use the smallest font size for long text
      } else if (flavorText.length < minLength) {
        return maxFontSize; // Use the largest font size for short text
      } else {
        // Dynamically calculate font size for text lengths in between
        const fontSizeRange = maxFontSize - minFontSize;
        const textLengthRange = maxLength - minLength;
        const textSizeRatio = (flavorText.length - minLength) / textLengthRange;
        return maxFontSize - (fontSizeRange * textSizeRatio);
      }
    };

    // Call the function and set the state
    setFlavorFontSize(calculateFlavorFontSize(card.flavor));
  }, [card.flavor]);


  const handleZoomToggle = () => setZoomMode(true); // This will be triggered by pinch gesture
  // const handleZoomToggle = () => console.log("pinched"); // This will be triggered by pinch gesture
  const handleCloseZoom = () => setZoomMode(false);

  const titleAnimatedStyle = useAnimatedStyle(() => ({
    width: `${animatedWidth.value}%`,
  }));


  // React to changes in progress
  React.useEffect(() => {
    animatedWidth.value = withTiming(progress * 100, {
      duration: 500, // Adjust timing to match your preference
      easing: Easing.linear,
    });
  }, [progress]);


  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{scale: scale.value}],
    };
  });

  const openModalIfNotCanceled = () => {
    if (!pressReleased.value) {
      setIsModalVisible(true);
    }
  };

  const handleZoomChange = (zoomed) => {
    setIsZoomed(zoomed);
  };

  const handleLongPressIn = () => {
    if (!isAuthenticated) {
      console.log('Not authenticated, showing login modal');
      setLoginModalVisible(true);
      return;
    }
    pressReleased.value = false;
    scale.value = withSpring(scaleUpSize, { // Slightly larger scale
      damping: 0.5, // Adjusted for slower animation
      stiffness: 10, // Adjusted for slower animation
    }, () => runOnJS(openModalIfNotCanceled)()); // Open the modal at the end of the spring
  };

  const handleLongPressOut = () => {
    pressReleased.value = true;
    scale.value = withSpring(1); // Return to original scale
  };

  useEffect(() => {
    Image.prefetch(card.art);
  }, [card.art]);


  useEffect(() => {
    if (isViewable && !autoplay) {
      // Logic to stop audio
      animatedWidth.value = 0;
      stopSound();
    }
    else if (isViewable && autoplay) {
      // Logic to play audio
      animatedWidth.value = 0;
      stopSound();
      playTitleAndFlavor(
        card.title_flavor_audio,
        card.title_flavor_audio_duration * 1000,
        // card.title_audio_duration * 1000, // Convert duration to milliseconds if needed
        // card.flavor_audio,
        // card.flavor_audio_duration * 1000 // Convert duration to milliseconds if needed
      );
    }
  }, [isViewable, autoplay]);

   const [lastTap, setLastTap] = useState(null);
   const timeoutRef = useRef(null); // Add this line

  const handleImageTap = () => {
    const now = Date.now();
    const DOUBLE_TAP_DELAY = 250; // milliseconds

    if (lastTap && (now - lastTap) < DOUBLE_TAP_DELAY) {
      // Detected a double tap, do nothing or handle accordingly
      console.log('Double tap detected, action cancelled.');
      clearTimeout(timeoutRef.current); // Clear the timeout for the first tap
    } else {
      // Not a double tap, proceed with action after setting a delay to allow for a potential second tap
      timeoutRef.current = setTimeout(() => { // Store the timeout ID
        if (onImageTap) {
          console.log('Single tap detected, action performed.');
          handlePlayPauseAudio();
        }
      }, DOUBLE_TAP_DELAY);
    }
    // Update the lastTap state to the current time
    setLastTap(now);
  };

  const navigateToUserProfile = () => {
    if (!isAuthenticated) {
      console.log('Not authenticated, showing login modal');
      setLoginModalVisible(true);
      return;
    }

    if (cardProfileData) {
      // navigation.navigate('UserProfile', { profile: cardProfileData });
      navigation.navigate('UserProfile', { profile_uuid: card.creator_uuid });
    } else {
      console.error('Card creator profile data not found');
      // Optionally, show an error message to the user
    }
  };

  useEffect(() => {
    if (card.next_sibling_uuid) {
      queryClient.prefetchQuery(['cardDetail', card.next_sibling_uuid], () => fetchCardByUUID(card.next_sibling_uuid));
    }

    if (card.prev_sibling_uuid) {
      queryClient.prefetchQuery(['cardDetail', card.prev_sibling_uuid], () => fetchCardByUUID(card.prev_sibling_uuid));
    }
  }, [card.next_sibling_uuid, card.prev_sibling_uuid, queryClient]);

  const handleRiffPress = async () => {
    // if (!isAuthenticated) {
    //   console.log('Not authenticated, showing login modal');
    //   setLoginModalVisible(true);
    //   return;
    // }

    openModal(); // this navigates to the creation screen
  };

  const handeEllipsisPress = () => {
    if (!isAuthenticated) {
      console.log('Not authenticated, showing login modal');
      setLoginModalVisible(true);
      return;
    }
    setIsModalVisible(true)
  }

  const handleLike = () => {
    if (!isAuthenticated) {
      console.log('Not authenticated, showing login modal');
      setLoginModalVisible(true);
      return;
    }
    performLike();
  }

  const handleBookmark = () => {
    if (!isAuthenticated) {
      console.log('Not authenticated, showing login modal');
      setLoginModalVisible(true);
      return;
    }
    performBookmark();
  }

  const handlePlayPauseAudio = () => {
    if (isLoading) return; // Do nothing if audio is loading

    if (isPlaying) {
      togglePlayPause();
    } else {
      // If the audio has finished (progress is 1) or hasn't started (progress is 0),
      // play from the beginning
      if (progress === 0 || progress >= 0.99) {
        stopSound();
        playTitleAndFlavor(card.title_flavor_audio, card.title_flavor_audio_duration * 1000);
      } else {
        // If the audio is paused in the middle, just resume
        togglePlayPause();
      }
    }
  };

  return (
  <View style={[styles.cardContainer]}>
    {/* Navigation buttons */}
    <View style={styles.siblingRiffLines}>
      {card.card_riffed_from && card.prev_sibling_uuid && <SvgRiffLineLeft />}
      {card.card_riffed_from && !card.prev_sibling_uuid && card.next_sibling_uuid && <View style={styles.placeholder} />}
      {card.card_riffed_from && !card.next_sibling_uuid && card.prev_sibling_uuid && <View style={styles.placeholder} />}
      {card.card_riffed_from && card.next_sibling_uuid && <SvgRiffLineRight />}
    </View>
    <SiblingNavigationButtons card={card} />

    <Animated.View style={[styles.Card, animatedStyle]}>
      <SvgSpurWithFWhiteOnRed style={styles.spur}/>
      <TouchableOpacity
        onPress={handleImageTap}
        style={styles.artTouchableOpacityStyle}
      >
        <NormalImage
          source={{uri: card.art}}
          style={styles.image}
          onPinch={handleZoomToggle}
          enableGestures={isAuthenticated}
        />
        <ZoomableImageModal source={{uri: card.art}} onClose={handleCloseZoom} isVisible={zoomMode}/>
        <Animated.View style={[styles.progressBar, titleAnimatedStyle]} />

        <View style={styles.positionRectangle}>
          <Text style={styles.positionText}>
            {`${card.position_in_lineage}/${card.total_lineage_length}`}
          </Text>
        </View>

        <PlayPauseButton
          isPlaying={isPlaying}
          onPress={handlePlayPauseAudio}
          style={styles.playPauseButton}
        />

      </TouchableOpacity>
      <View style={styles.textContainer}>
        <View style={styles.titleContainer}>
          <Text
            style={styles.name}
            adjustsFontSizeToFit={true}
            numberOfLines={3}
            minimumFontScale={0.5}
          >
            {card.name}
          </Text>
          <TouchableOpacity
            onPress={handeEllipsisPress}
            style={styles.ellipsisButton}
            hitSlop={{
              top: 20,
              bottom: 20,
              left: 20,
              right: 20,
            }}
          >
          <Ionicons name="ellipsis-horizontal" size={32} color={theme.colors.white}/>
        </TouchableOpacity>
        </View>
        <View style={styles.flavorContainer}>
          <Text
            style={styles.flavor}
            adjustsFontSizeToFit={true}
            numberOfLines={8}
            minimumFontScale={0.7}
          >
            {card.processed_flavor.replace(/`/g, '')}
            {/*Boblin wandered the forest in search of purpose, haunted by memories of the home he could no longer call his own.*/}
            {/*In the trench, a small songbird perched above, its melody a reminder of simpler days and a hope for peace to come.*/}
          </Text>
        </View>

        {/* Card bottom container with user and action buttons */}
        <View style={styles.bottomContainer}>
          <View style={styles.userContainer}>
            {/*<Text style={styles.user}>by </Text>*/}
            <TouchableOpacity
              onPress={navigateToUserProfile}
              style={styles.usernameContainer}
            >
              <UserCircle profile_uuid={card.creator_uuid} size={35} />
              <Text
                style={[styles.user]}
                numberOfLines={2}
              >
                {card.creator_preferred_username}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.buttonsContainer}>
            <TouchableOpacity
              style={styles.share}
              onPress={shareCard}
            >
              <MaterialIcons
                name="ios-share"
                size={34}
                color={theme.colors.lighterBlue}
              />
            </TouchableOpacity>
            <FontAwesome
              name={"bookmark"}
              size={26}
              color={bookmarkColor}
              onPress={handleBookmark}
              style={styles.bookmarkButton}
            />
            <FontAwesome
              name="heart"
              size={26}
              color={heartColor}
              onPress={handleLike}
              style={styles.heartButton}
            />
            <TouchableOpacity
              onPress={handleRiffPress}
              style={[
                styles.riffButton,
              ]}
            >
              <SvgRiff width={30} height={30}/>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Animated.View>
    {isAuthenticated && (<CardDetailsModal
      isVisible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
      card={card}
      profileData={profileData}
      navigateToUserProfile={navigateToUserProfile}
    />)}
    <LoginPromptModal
      isVisible={isLoginModalVisible}
      onConfirm={() => console.log('User wants to sign up or log in')}
      onCancel={() => setLoginModalVisible(false)}
    />

    <SvgRiffLine style={styles.riffLine}/>
  </View>
  );
}


const styles = StyleSheet.create({
  cardContainer: {
    alignSelf: 'center',
    alignItems: 'flex-start', // Align contents to the left
    height: cardScaleSize(cardHeightOuter),
  },
  spur: {
    alignSelf: 'center',
    position: 'absolute',
    marginTop: 5,
    zIndex: 1,
  },
  Card: {
    flex: 1,
    alignItems: 'flex-start', // Align contents to the left
    backgroundColor: theme.colors.gray,
    borderRadius: theme.borderRadius.large,
    padding: theme.spacings.small,
    marginVertical: cardMarginVertical,
    marginHorizontal: cardMarginHorizontal,
    height: cardScaleSize(cardHeight),
    width: cardScaleSize(cardWidth), // Use the calculated width
  },
  bookmarkButton: {
    paddingRight: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ellipsis: {
    paddingRight: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  share: {
    paddingRight: 10,
    marginBottom: 6,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  heartButton: {
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  riffButton: {
    paddingLeft: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabledRiffButton: {
    opacity: 0.5,
  },
  riffLine: {
    alignSelf: 'center',
  },
  fullScreenContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.black,
  },
  imageContainer: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.black,
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  progressBarContainer: {
    position: 'absolute',
    bottom: 0, // Align to the bottom of the imageContainer
    left: 0,
    right: 0,
    height: 10, // Adjust thickness of the progress bar
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Background of the progress bar
  },
  image: {
    alignSelf: 'center',
    width: cardScaleSize(imageWidth),
    height: cardScaleSize(imageWidth),
    resizeMode: 'contain',
  },
  artTouchableOpacityStyle: {
    // Set dimensions to match the image or the desired size
    marginTop: theme.spacings.small,
    width: '100%', // e.g., 100% or a fixed value
    height: cardScaleSize(imageWidth),
    // e.g., a fixed value or proportional to width
    marginBottom: 0,
  },
  textContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'flex-start', // Align text to the left
    marginLeft: theme.spacings.small,
    marginRight: theme.spacings.small,
  },
  name: {
    flex: 1,
    color: theme.colors.redPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.scaledSizes.large,
    marginTop: theme.spacings.medium,
    marginBottom: theme.spacings.small,
    paddingTop: 0,
    fontWeight: theme.fonts.weight.bold,
    alignSelf: 'flex-start', // Ensure the title is aligned to the left
    // width: '100%', // Give some padding inside the card
    paddingRight: theme.spacings.xxlarge,
  },
  flavor: {
    color: theme.colors.white,
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.scaledSizes.medium,
    alignSelf: 'flex-start', // Ensure the text is aligned to the left
    width: '95%', // Give some padding inside the card
  },
  closeIcon: {
    position: 'absolute',
    top: 50,
    right: 10,
    padding: 20, // Padding to make it easier to tap
  },
  dragIndicator: {
    width: 40, // Width of the line
    height: 5, // Height of the line
    borderRadius: 2.5, // Half of the height to make it rounded
    backgroundColor: '#ccc', // Color of the line
    alignSelf: 'center', // Center it horizontally
    marginTop: 10, // Space from the top
    marginBottom: 10, // Space from the rest of the content
  },
  bottomContainer: {
    width: '95%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: "auto",
  },
  userContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
  usernameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  user: {
    color: theme.colors.lighterBlue,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.scaledSizes.large,
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: theme.spacings.xsmall
  },
  underline: {
    textDecorationLine: 'underline',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between', // Push buttons to the right
    alignItems: 'center',
    padding: 10,
  },
  heartIcon: {
    // Placeholder for heart icon styles (if required)
  },
  // Navigation buttons container
  siblingRiffLines: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'absolute', // Position the buttons over the image
    top: -40,
    paddingHorizontal: 10, // Add horizontal padding
    zIndex: 1, // Ensure it's above other card elements
  },
  navigationContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'absolute', // Position the buttons over the image
    left: 0,
    right: 0,
    zIndex: 1, // Ensure it's above other card elements
  },
  // Circle button styles
  circleButton: {
    alignSelf: 'center', // Center the button horizontally
    alignItems: 'center', // Center icon horizontally
  },
  // Add styles for the arrows if using Text, or style the Icon component directly in the JSX
  arrowIcon: {
    // Add specific stylings for the icon component, if necessary
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: 22,
  },
  modalView: {
    position: 'absolute',
    top: 0,
    marginTop: 0,
    backgroundColor: theme.colors.beige,
    borderRadius: 20,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: 15,
    paddingTop: 70,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: "100%",
    zIndex: 10,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    zIndex: 0,
  },
  modalHeader: {
    backgroundColor: theme.colors.gray,
    borderRadius: 20,
    padding: 15,
    alignSelf: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  modalTitle: {
    alignSelf: 'center',
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.large,
  },
  cardName: {
    color: theme.colors.redPrimary,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.large,
  },
  thumbnailStyle: {
    width: 80, // Adjust size as needed
    height: 80, // Adjust size as needed
    borderRadius: 10, // For a rounded thumbnail
    borderWidth: 0.5,
    marginLeft: 10,
  },
  // titleContainer: {
  //   position: 'relative',
  //   overflow: 'hidden', // Ensure the overlay is clipped to the container bounds
  //   width: '100%',
  // },
  titleContainer: {
    flexDirection: 'row',
    // alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: theme.spacings.small,
  },
  friendIcon: {
    marginLeft: theme.spacings.xsmall,
  },
  progressBar: {
    height: 3, // Height of the progress bar
    backgroundColor: theme.colors.white,
    position: 'absolute',
    bottom: -3, // Align to the bottom of the container
    left: 0,
  },
  flavorContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'flex-start', // Align text to the left
  },
  positionRectangle: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: theme.colors.transparent_gray,
    paddingHorizontal: 8,
    paddingVertical: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  positionText: {
    color: theme.colors.white,
    fontSize: theme.fonts.scaledSizes.small,
    fontFamily: theme.fonts.family.regular,
  },
  playPauseButton: {
    position: 'absolute',
    bottom: 2,
    right: 2,  // Changed from 'left' to 'right'
  },
  // playPauseButton: {
  //   position: 'absolute',
  //   bottom: 10,
  //   right: 10,
  //   backgroundColor: theme.colors.redPrimary,
  //   borderRadius: 20,
  //   width: 40,
  //   height: 40,
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
  ellipsisButton: {
    position: 'absolute',
    top: theme.spacings.medium, // shoudl match the name margin top
    right: theme.spacings.medium,
  },
});


export default Card;
export { truncateUsername }
