import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions } from 'react-native';
import { Image } from 'expo-image';
import { useNavigation } from '@react-navigation/native';
import ContentLoader, { Rect } from 'react-content-loader/native';
import theme from '../styles/theme';
import CardCountIndicator from "./CardCountIndicator";

const windowWidth = Dimensions.get('window').width;
const TINY_WORLD_WIDTH = Math.min(windowWidth - 40, 300);
const TINY_WORLD_HEIGHT = 70; // Slightly increased for better proportions
const IMAGE_WIDTH = 120; // Adjusted to maintain aspect ratio
const IMAGE_HEIGHT = IMAGE_WIDTH / (16/9); // Assuming 16:9 aspect ratio
const CARD_PADDING = theme.spacings.xsmall;

const TinyWorldSkeleton = () => (
  <ContentLoader
    speed={1}
    width={TINY_WORLD_WIDTH}
    height={TINY_WORLD_HEIGHT}
    backgroundColor={theme.colors.loadingBackground}
    foregroundColor={theme.colors.loadingForeground}
  >
    <Rect x="0" y="0" rx="8" ry="8" width={TINY_WORLD_WIDTH} height={TINY_WORLD_HEIGHT} />
  </ContentLoader>
);

const TinyWorldComponent = React.memo(({ world, onPress }) => {
  const navigation = useNavigation();

  if (!world) {
    return <TinyWorldSkeleton />;
  }

  const navigateToWorldDetail = () => {
    if (world.root_card) {
      navigation.navigate('CardDetail', {
        card: world.root_card,
        startAtTop: true,
      });
    } else {
      navigation.navigate('WorldDetail', {world_uuid: world.uuid});
    }
  };

  return (
    <TouchableOpacity onPress={navigateToWorldDetail} style={styles.container}>
      {world.art ? (
        <Image source={{uri: world.art}} style={styles.image} />
      ) : (
        <View style={styles.placeholderImage}>
          <Text style={styles.placeholderText}>No Image</Text>
        </View>
      )}
      <View style={styles.textContainer}>
        <Text style={styles.worldName} numberOfLines={1} ellipsizeMode="tail">
          {world.name}
        </Text>
        <View style={styles.bottomRow}>
          <Text style={styles.creatorName} numberOfLines={1} ellipsizeMode="tail">
            @{world.creator?.preferred_username || 'unknown'}
          </Text>
          <CardCountIndicator count={world.total_cards} size="small" outline={true} />
        </View>
      </View>
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  container: {
    width: TINY_WORLD_WIDTH,
    height: TINY_WORLD_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.loadingBackground,
    borderRadius: theme.borderRadius.medium,
    overflow: 'hidden',
    padding: CARD_PADDING,
  },
  image: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    borderRadius: theme.borderRadius.small,
  },
  placeholderImage: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    backgroundColor: theme.colors.lightGray,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.borderRadius.small,
  },
  placeholderText: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.tiny,
  },
  textContainer: {
    height: IMAGE_HEIGHT,
    flex: 1,
    marginLeft: theme.spacings.small,
    justifyContent: 'space-around',
  },
  worldName: {
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.yellowSecondary, // Changed to yellow primary
  },
  creatorName: {
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.small,
    color: theme.colors.bluePrimary,
  },
  bottomRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default TinyWorldComponent;
