import React, { useContext, useEffect } from 'react';
import {View} from 'react-native';
import { AuthContext } from '../../contexts/AuthContext';
import theme from "../../styles/theme";

const WorldDetailResolver = ({ route, navigation }) => {
  const { world_uuid } = route.params; // Assuming you pass the world's UUID as a parameter
  const { isAuthenticated } = useContext(AuthContext);

  const handlePrivateWorld = () => {
    navigation.replace('Authenticated', {
      screen: 'Tabs',
      params: {
        screen: 'Home',
        params: {
          screen: 'WorldDetail',
          params: { world_uuid: world_uuid },
        },
      },
    });
  }

  const handlePublicWorld = () => {
    navigation.replace('PublicWorldDetail', { world_uuid: world_uuid });
    // navigation.navigate('WorldDetail', { world_uuid: world_uuid });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isAuthenticated) {
        console.log(`Navigating to authenticated WorldDetail for world ${world_uuid}`)
        handlePrivateWorld();
      } else {
        console.log(`Navigating to public WorldDetail for world ${world_uuid}`)
        handlePublicWorld();
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [isAuthenticated, navigation, world_uuid]);

  return (
    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: theme.colors.beige}}>
    </View>
  );
};

export default WorldDetailResolver;
