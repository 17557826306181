import React, {useContext, useEffect, useLayoutEffect} from 'react';
import {View, StyleSheet, TouchableOpacity, ScrollView, Linking} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import BigWorldComponent from '../components/BigWorldComponent';
import theme from "../styles/theme";
import useWorlds from "../hooks/useWorlds";
import AppStoreBadge from "../assets/App_Store_Badge";
import {HeaderBackButton} from "@react-navigation/elements";
import {AuthContext} from "../contexts/AuthContext";
import alert from "../utils/alert";
import HeaderTitle from "../components/HeaderTitle";


const APP_STORE = process.env.EXPO_PUBLIC_IOS_APP_URL;


const openURL = (url) => {
  Linking.canOpenURL(url)
  .then((supported) => {
    if (!supported) {
      console.log("Can't handle URL: " + url);
    } else {
      return Linking.openURL(url);
    }
  })
  .catch((err) => console.error('An error occurred', err));
};

const handleAppStorePress = () => {
  if (APP_STORE) {
    openURL(APP_STORE);
  } else {
    alert('Coming Soon', 'The app is coming soon to the App Store.');
  }
};


const WorldDetailScreen = ({ route }) => {
  const { world_uuid } = route.params;
  const navigation = useNavigation();
  const { isAuthenticated } = useContext(AuthContext);
  const { getWorld } = useWorlds();
  const { data, isLoading, error } = getWorld(world_uuid);
  const world = data?.world;

  useEffect(() => {
    if (world && world.root_card) {
      if (isAuthenticated) {
        handlePrivateCard();
      } else {
        handlePublicCard();
      }
    }
  }, [world, isAuthenticated]);

  const handlePrivateCard = () => {
    navigation.replace('Authenticated', {
      screen: 'Tabs',
      params: {
        screen: 'Home',
        params: {
          screen: 'CardDetail',
          params: { card_uuid: world.root_card.uuid, startAtTop: true },
        },
      },
    });
  };

  const handlePublicCard = () => {
    navigation.replace('PublicCardDetail', { card_uuid: world.root_card.uuid, startAtTop: true });
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerStyle: {
        backgroundColor: theme.colors.beige,
        shadowColor: 'transparent',
        elevation: 0,
        borderBottomWidth: 0,
      },
      headerTitleStyle: {
        // color: theme.colors.beige,
        fontFamily: theme.fonts.family.medium,
        fontSize: theme.fonts.sizes.large,
      },
      headerRight: () => isAuthenticated ? (
        <View style={styles.headerRightContainer}>
        </View>
      ) : (
        <TouchableOpacity style={styles.appStoreButton} onPress={handleAppStorePress}>
          <AppStoreBadge style={{transform: [{scale: 1}]}}/>
        </TouchableOpacity>
      ),
      headerLeft: (props) => (
      <View style={styles.headerLeftContainer}>
        <HeaderBackButton {...props} />
      </View>
    ),
      headerShown: true,
      headerTitle: () => isAuthenticated ? (
        <Ionicons name="planet-outline" size={24}/>
      ) : (
        <HeaderTitle isAuthenticated={isAuthenticated}/>
      ),
    });
  }, [navigation, isAuthenticated]);

  return (
    <ScrollView style={styles.container}>
      <BigWorldComponent world={world} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.beige,
  },
  closeButton: {
    position: 'absolute',
    top: 40,
    right: 20,
    zIndex: 1,
  },
  appStoreButton: {
    marginHorizontal: theme.spacings.small,
  },
});

export default WorldDetailScreen;
