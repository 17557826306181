// UserWorldsListScreen.js
import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import { fetchUserCreatedWorlds } from '../utils/api/cardsService';
import theme from '../styles/theme';
import {FontAwesome, Ionicons} from '@expo/vector-icons';
import WorldComponent from "../components/WorldComponent";
import useWorlds from "../hooks/useWorlds";
import API_ENDPOINTS from "../apiEndpoints";
import CreateButton from "../components/buttons/CreateButton";

const WorldItem = ({ item, onPress }) => (
  <TouchableOpacity style={styles.worldItem} onPress={() => onPress(item)}>
    <WorldComponent world={item} disableTouch={true} />
    {/*<Text style={styles.worldName}>{item.name}</Text>*/}
    {/*<Text style={styles.worldKernel} numberOfLines={2}>{item.kernel}</Text>*/}
  </TouchableOpacity>
);

const UserWorldsListScreen = ({ navigation, route }) => {
  const { onSelectWorld } = route.params || {};

  const {
    worlds,
    loadingWorlds,
    fetchMoreWorlds,
    nextPageURL,
    handleRefresh,
  } = useWorlds(API_ENDPOINTS.BIG_FRIENDS_WORLDS);

  const handleWorldPress = (world) => {
    if (onSelectWorld) {
      onSelectWorld(world);
    }
    // navigation.navigate('ExpandedInput', { selectedWorld: world });
    navigation.goBack()
  };

  const handleCreateWorld = () => {
    navigation.navigate('CreateWorld');
  };

  return (
    <View style={styles.container}>
      <FlatList
        data={worlds}
        renderItem={({ item }) => <WorldItem item={item} onPress={handleWorldPress} />}
        ListHeaderComponent={
          <CreateButton
            onPress={handleCreateWorld}
            text="CREATE NEW WORLD"
          />
        }
        keyExtractor={(item) => item.uuid}
        ListEmptyComponent={
          !loadingWorlds && <Text style={styles.emptyText}>You haven't created any worlds yet.</Text>
        }
        refreshing={loadingWorlds}
        onRefresh={handleRefresh}
        onEndReached={fetchMoreWorlds}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.beige,
    alignItems: 'center',
  },
  worldItem: {
    marginVertical: theme.spacings.small,
  },
  // buttonContainer: {
  //   marginVertical: theme
  // },
  worldName: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
    marginBottom: theme.spacings.small,
  },
  worldKernel: {
    fontSize: theme.fonts.sizes.small,
    color: theme.colors.gray,
  },
  emptyText: {
    textAlign: 'center',
    marginTop: theme.spacings.large,
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.gray,
  },
  createWorldButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
    padding: theme.spacings.medium,
    marginVertical: theme.spacings.small,
    marginHorizontal: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
  },
  createWorldButtonText: {
    marginLeft: theme.spacings.small,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.redPrimary,
  },
});

export default UserWorldsListScreen;
