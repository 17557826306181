import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  FlatList,
  Text,
  TouchableOpacity,
  View,
  Linking,
  ActivityIndicator
} from 'react-native';
import Card, { cardHeightOuter, CardSkeleton, cardWidth } from '../components/Card';
import { useQuery, useQueryClient } from 'react-query';
import {
  fetchCardByUUID,
  fetchWorld
} from "../utils/api/cardsService";
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import useCardDetails from "../hooks/useCardDetails";
import { HeaderBackButton } from '@react-navigation/elements';
import SvgRiff from "../assets/images/Riff";
import { ScaledSheet } from 'react-native-size-matters';
import theme, { cardScaleSize } from "../styles/theme";
import AutoPlayToggle from "../components/buttons/AutoPlayToggle";
import { useAudioPlayer } from "../contexts/AudioPlayerContext";
import { useAutoplay } from "../contexts/AutoplayContext";
import WorldComponent, { WORLD_HEIGHT } from "../components/WorldComponent";
import BigWorldComponent, {BIG_WORLD_COMPONENT_HEIGHT, BOTTOM_SECTION_HEIGHT} from "../components/BigWorldComponent";
import SvgFabylHeader from "../assets/images/FabylHeader";
import { AuthContext } from "../contexts/AuthContext";
import LoginPromptModal from "../components/modals/LoginPromptModal";
import AddFriendToRiffModal from "../components/modals/AddFriendToRiffModal";
import { Ionicons } from "@expo/vector-icons";
import alert from "../utils/alert";
import AppStoreBadge from "../assets/App_Store_Badge";
import WorldNavigationBar from "../components/WorldNavigationBar";
import { debounce } from 'lodash';
import { Animated } from 'react-native';
import useWorlds from "../hooks/useWorlds";
import HeaderTitle from "../components/HeaderTitle";
import CreateButton from "../components/buttons/CreateButton";


const APP_STORE = process.env.EXPO_PUBLIC_IOS_APP_URL;

const openURL = (url) => {
  Linking.canOpenURL(url)
    .then((supported) => {
      if (!supported) {
        console.log("Can't handle URL: " + url);
      } else {
        return Linking.openURL(url);
      }
    })
    .catch((err) => console.error('An error occurred', err));
};

const handleAppStorePress = () => {
  if (APP_STORE) {
    openURL(APP_STORE);
  } else {
    alert('Coming Soon', 'The app is coming soon to the App Store.');
  }
};


const Overlay = () => (
  <View style={styles.overlay}>
    <ActivityIndicator size="large" color={theme.colors.primary}/>
  </View>
);


const CardItem = React.memo(({ uuid, isViewable, onImageTap, openModal }) => {
  const { data, isLoading } = useQuery(
    ['cardDetail', uuid],
    () => fetchCardByUUID(uuid),
    { staleTime: Infinity }
  );

  if (isLoading || !data) {
    return <CardSkeleton />;
  }

  return (
    <Card
      card={data}
      onImageTap={onImageTap}
      openModal={() => openModal(data)}
      isViewable={isViewable}
    />
  );
}, (prevProps, nextProps) => {
  return prevProps.uuid === nextProps.uuid && prevProps.isViewable === nextProps.isViewable;
});

// const HEADER_HEIGHT = WORLD_HEIGHT + theme.spacings.xxlarge;
const HEADER_HEIGHT = BIG_WORLD_COMPONENT_HEIGHT + theme.spacings.xxlarge;

const TopHeader = React.memo(({ card }) => {
  const { getWorld } = useWorlds();
  const { data, isLoading, error } = getWorld(card?.world_uuid);
  const world = data?.world;

  return (
    <View style={{ height: HEADER_HEIGHT, alignItems: "center", alignSelf: "center" }}>
      <BigWorldComponent world={world} disableTouch={false} isLoading={isLoading}/>
    </View>
  );
});

export default function CardDetailScreen({ route }) {
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  const { stopSound } = useAudioPlayer();
  const { isAuthenticated } = useContext(AuthContext);
  const [isLoginModalVisible, setLoginModalVisible] = useState(false);
  const [isNotFriendsModalVisible, setIsNotFriendsModalVisible] = useState(false);
  const [contentSizeReady, setContentSizeReady] = useState(false);
  const [currentWorldUUID, setCurrentWorldUUID] = useState(null);
  const [overlayVisible, setOverlayVisible] = useState(true);
  const [totalLineageLength, setTotalLineageLength] = useState(0);
  const [positionInLineage, setPositionInLineage] = useState(0);
  const scrollY = useRef(new Animated.Value(0)).current;

  const { autoplay } = useAutoplay();
  const initialCardThumbnail = route.params.card || (route.params.card_uuid ? { uuid: route.params.card_uuid } : null);
  const startAtTop = route.params.startAtTop || false;


  const flatListRef = useRef(null);

  const headerBackgroundColor = scrollY.interpolate({
    inputRange: [0, BIG_WORLD_COMPONENT_HEIGHT],
    outputRange: [theme.colors.gray, theme.colors.transparent_beige],
    extrapolate: 'clamp'
  });

  const {
    mainCard,
    cards,
    isLoading,
    isError,
    lastCardUuid,
    handleViewableItemsChanged,
    currentViewableCard,
    currentViewableCardUuid,
    updateMainCardData,
  } = useCardDetails(initialCardThumbnail.uuid);

  useEffect(() => {
    if (currentViewableCard) {
      setTotalLineageLength(currentViewableCard.total_lineage_length);
      setPositionInLineage(currentViewableCard.position_in_lineage);
    }
  }, [currentViewableCard]);

  useFocusEffect(
    React.useCallback(() => {
      updateMainCardData();
    }, [])
  );

  useEffect(() => {
    if (currentViewableCard && currentViewableCard.world_uuid !== currentWorldUUID) {
      setCurrentWorldUUID(currentViewableCard.world_uuid);
    }
  }, [currentViewableCard]);

  useEffect(() => {
    if (!isAuthenticated) {
      const timer = setTimeout(() => {
        console.log('Not authenticated, showing login modal');
        setLoginModalVisible(true);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [isAuthenticated]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        stopSound();
      };
    }, [])
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerStyle: {
        // backgroundColor: headerBackgroundColor,
        backgroundColor: theme.colors.beige,
        shadowColor: 'transparent',
        elevation: 0,
        borderBottomWidth: 0,
      },
      // headerTintColor: theme.colors.beige,
      headerTitleStyle: {
        // color: theme.colors.beige,
        fontFamily: theme.fonts.family.medium,
        fontSize: theme.fonts.sizes.large,
      },
      headerRight: () => isAuthenticated ? (
        <View style={styles.headerRightContainer}>
        </View>
      ) : (
        <TouchableOpacity style={styles.appStoreButton} onPress={handleAppStorePress}>
          <AppStoreBadge style={{transform: [{scale: 1}]}}/>
        </TouchableOpacity>
      ),
      headerLeft: (props) => (
      <View style={styles.headerLeftContainer}>
        <HeaderBackButton {...props} />
        {/*<View style={styles.storyPosContainer}>*/}
        {/*  <Text style={styles.storyPosCounter}>*/}
        {/*    {positionInLineage} / {totalLineageLength}*/}
        {/*  </Text>*/}
        {/*</View>*/}
      </View>
    ),
      headerShown: true,
      headerTitle: () => isAuthenticated ? (
        <Ionicons name="planet-outline" size={24} />
      ) : (
        <HeaderTitle isAuthenticated={isAuthenticated} />
      ),
    });
  }, [navigation, isAuthenticated, initialCardThumbnail, positionInLineage, totalLineageLength]);

  const viewabilityConfig = useMemo(() => ({
    itemVisiblePercentThreshold: 50,
    minimumViewTime: 0,
  }), []);

  const viewabilityConfigCallbackPairs = useRef([
    {
      viewabilityConfig,
      onViewableItemsChanged: handleViewableItemsChanged,
    },
  ]);

  // const initialCardIndex = useMemo(() => cards.findIndex(card => card.uuid === initialCardThumbnail.uuid), [cards, initialCardThumbnail]);
  const initialCardIndex = useMemo(() =>
    startAtTop ? 0 : cards.findIndex(card => card.uuid === initialCardThumbnail.uuid),
    [cards, initialCardThumbnail, startAtTop]
  );
  const validInitialIndex = initialCardIndex !== -1;
  const scaledCardHeight = cardScaleSize(cardHeightOuter);

  const getItemLayout = useCallback((data, index) => ({
    length: scaledCardHeight,
    offset: scaledCardHeight * index + HEADER_HEIGHT,
    index,
  }), [scaledCardHeight]);

  const isFocused = useIsFocused();

  useEffect(() => {
    const performScroll = (animate = false) => {
      if (flatListRef.current) {
        if (startAtTop) {
          // Scroll to the top (header)
          flatListRef.current.scrollToOffset({ animated: animate, offset: 0 });
        } else if (validInitialIndex) {
          // Existing logic to scroll to the specific card
          const headerHeight = HEADER_HEIGHT;
          const offset = scaledCardHeight * initialCardIndex + headerHeight;
          flatListRef.current.scrollToOffset({ animated: animate, offset });
        }
      }
    };

    if (contentSizeReady) {
      performScroll();
    }

    if (isFocused) {
      const timeoutId = setTimeout(() => {
        performScroll(true);
        setOverlayVisible(false); // Hide the overlay after scrolling
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [contentSizeReady, isFocused, initialCardIndex, validInitialIndex, scaledCardHeight, startAtTop]);

  const setCurrentViewableCard = useCallback((uuid) => {
    navigation.navigate("FullscreenDetail", { card: { uuid } })
  }, [navigation]);

  const gotToRiffScreen = useCallback((card) => {
    // if (!isAuthenticated) {
    //   console.log('Not authenticated, showing login modal');
    //   setLoginModalVisible(true);
    //   return;
    // }
    navigation.navigate('ExpandedInput', {
      cardRiffedFrom: card,
      returnFromCreation: false,
    });
  }, [isAuthenticated, navigation]);

  const renderItem = useCallback(({ item }) => {
    const isViewable = item.uuid === currentViewableCardUuid;

    return (
      <CardItem
        uuid={item.uuid}
        isViewable={isViewable}
        onImageTap={setCurrentViewableCard}
        openModal={gotToRiffScreen}
      />
    );
  }, [currentViewableCardUuid, setCurrentViewableCard, gotToRiffScreen]);

  const renderFooter = useCallback(() => {
    if (!lastCardUuid) {
      return null;
    }

    const { data, isLoading } = useQuery(
      ['cardDetail', lastCardUuid],
      () => fetchCardByUUID(lastCardUuid),
      { staleTime: Infinity }
    );

    return (
      <View>
        {/*<CreateButton*/}
        {/*  onPress={() => gotToRiffScreen(data)}*/}
        {/*  text="RIFF THE NEXT CHAPTER"*/}
        {/*  createType={"riff"}*/}
        {/*/>*/}
        <TouchableOpacity
          onPress={() => gotToRiffScreen(data)}
          style={styles.bottomRiffBox}
        >
          <View style={styles.bottomRiffRow}>
            <Text style={styles.bottomRiffText}>RIFF THE NEXT CHAPTER</Text>
            <SvgRiff/>
          </View>
        </TouchableOpacity>
        {/*<WorldNavigationBar currentWorldUUID={currentWorldUUID} />*/}
      </View>
    );
  }, [lastCardUuid, gotToRiffScreen, currentWorldUUID]);

  if (isLoading) return <Overlay />;
  if (isError) return <Text>Error Loading Card</Text>;

  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.beige }}>
      {overlayVisible && (
        <Overlay />
      )}
      <Animated.FlatList
        ref={flatListRef}
        data={cards}
        onContentSizeChange={() => setContentSizeReady(true)}
        renderItem={renderItem}
        ListHeaderComponent={<TopHeader card={mainCard}/>}
        keyExtractor={(item) => item.uuid}
        viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
        getItemLayout={getItemLayout}
        // initialScrollIndex={validInitialIndex ? initialCardIndex : null}
        ListFooterComponent={renderFooter}
        onEndReached={updateMainCardData}
        onEndReachedThreshold={1.0}
        onScroll={Animated.event(
          [{nativeEvent: {contentOffset: {y: scrollY}}}],
          {useNativeDriver: false}
        )}
        scrollEventThrottle={16}
        {...(autoplay ? {
          snapToInterval: scaledCardHeight,
          decelerationRate: 'fast',
          snapToAlignment: 'start',
          snapToOffsets: cards.map((_, index) => index * scaledCardHeight + HEADER_HEIGHT),
        } : {})}
      />
      {!isAuthenticated && (
        <LoginPromptModal
          isVisible={isLoginModalVisible}
          onConfirm={() => console.log('User wants to sign up or log in')}
          onCancel={() => setLoginModalVisible(false)}
        />
      )}
      <AddFriendToRiffModal
        isVisible={isNotFriendsModalVisible}
        onClose={() => setIsNotFriendsModalVisible(false)}
      />
    </View>
  );
}

const styles = ScaledSheet.create({
  headerRightContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: 90,
    marginHorizontal: theme.spacings.small,
  },
  headerLeftContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 90,
    marginHorizontal: theme.spacings.small,
  },
  storyPosContainer: {
    // backgroundColor: theme.colors.redPrimary,
    // borderRadius: 10,
    // padding: theme.spacings.xsmall,
  },
  storyPosCounter: {
    color: theme.colors.redPrimary,
    fontSize: theme.fonts.scaledSizes.medium,
    fontFamily: theme.fonts.family.bold,
  },
  bottomRiffBox: {
    width: cardScaleSize(cardWidth),
    paddingTop: theme.spacings.medium,
    paddingBottom: theme.spacings.medium,
    marginTop: 10,
    alignSelf: 'center',
    backgroundColor: theme.colors.gray,
    borderRadius: 10,
    color: theme.colors.yellowPrimary,
    marginBottom: theme.spacings.large,
  },
  bottomRiffRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomRiffText: {
    color: theme.colors.yellowPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.scaledSizes.medium,
    alignItems: 'center',
    marginRight: theme.spacings.small,
  },
  openInAppButton: {
    backgroundColor: theme.colors.bluePrimary, // Example blue color for the button
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  openInAppButtonText: {
    color: theme.colors.white, // White text color
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
  },
  disabledRiffButton: {
    opacity: 0.5,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: 24, // Adjust based on your icon's size
    height: 24, // Adjust based on your icon's size
    marginRight: 8, // Space between icon and text
  },
  spur: {
    marginLeft: 20,
    zIndex: 1,
    transform: [{ scale: 2 }],
  },
  appStoreButton: {
    marginHorizontal: theme.spacings.small,
  },
  navigationButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  navigationButton: {
    backgroundColor: theme.colors.bluePrimary,
    padding: 10,
    borderRadius: 5,
    margin: 10,
  },
  navigationButtonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.scaledSizes.medium,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'rgba(255, 255, 255, 0.7)', // semi-transparent background
    backgroundColor: theme.colors.beige,
    zIndex: 1000, // Ensure it is above other elements
  },
});
