import React, { useRef, useState } from 'react';
import { View, StyleSheet, FlatList, Text, TouchableOpacity, Dimensions, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import WorldComponent, { WORLD_WIDTH as ORIGINAL_WORLD_WIDTH, WORLD_HEIGHT } from './WorldComponent';
import ContentLoader, { Rect } from 'react-content-loader/native';
import useWorlds from '../hooks/useWorlds';
import theme from '../styles/theme';

const WORLD_SPACING = 20; // Adjust this value to increase/decrease space between worlds
const WORLD_WIDTH = ORIGINAL_WORLD_WIDTH + WORLD_SPACING;
const CHEVRON_CONTAINER_WIDTH = 50

const { width: screenWidth } = Dimensions.get('window');

const FeaturedWorldsGallerySkeleton = ({ numWorlds = 5 }) => {
  const worldWidth = ORIGINAL_WORLD_WIDTH;
  const worldHeight = WORLD_HEIGHT;
  const spacing = WORLD_SPACING;
  const containerWidth = screenWidth - (2 * theme.spacings.small); // Adjust based on your container's horizontal padding

  return (
    <View style={styles.container}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollViewContent}
      >
        <ContentLoader
          speed={1}
          width={(worldWidth + spacing) * numWorlds}
          height={worldHeight}
          viewBox={`0 0 ${(worldWidth + spacing) * numWorlds} ${worldHeight}`}
          backgroundColor={theme.colors.loadingBackground}
          foregroundColor={theme.colors.loadingForeground}
        >
          {[...Array(numWorlds)].map((_, index) => (
            <Rect
              key={index}
              x={index * (worldWidth + spacing)}
              y="0"
              rx="8"
              ry="8"
              width={worldWidth}
              height={worldHeight}
            />
          ))}
        </ContentLoader>
      </ScrollView>
    </View>
  );
};


const FeaturedWorldsGallery = ({ limit = 5 }) => {
  const { getLandingPageFeaturedWorlds } = useWorlds();
  const { data: featuredWorlds, isLoading, error } = getLandingPageFeaturedWorlds(limit);
  const flatListRef = useRef(null);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

  if (isLoading) {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Featured Worlds</Text>
        <FeaturedWorldsGallerySkeleton numWorlds={limit} />
      </View>
    );
  }

  if (error) {
    return <Text style={styles.errorText}>Error loading featured worlds</Text>;
  }

  if (!featuredWorlds || featuredWorlds.length === 0) {
    return null;
  }

  const renderWorldItem = ({ item }) => (
    <View style={styles.worldItemContainer}>
      <WorldComponent world={item} showBanner={true}/>
    </View>
  );

  const scrollOneWorld = () => {
    if (flatListRef.current) {
      const newPosition = currentScrollPosition + WORLD_WIDTH;
      const maxScroll = (featuredWorlds.length - 1) * WORLD_WIDTH;

      if (newPosition <= maxScroll) {
        flatListRef.current.scrollToOffset({
          offset: newPosition,
          animated: true
        });
        setCurrentScrollPosition(newPosition);
      } else {
        // Scroll back to the start if we're at the end
        flatListRef.current.scrollToOffset({
          offset: 0,
          animated: true
        });
        setCurrentScrollPosition(0);
      }
    }
  };

  const handleScroll = (event) => {
    setCurrentScrollPosition(event.nativeEvent.contentOffset.x);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>FEATURED WORLDS</Text>
      <View style={styles.galleryContainer}>
        <FlatList
          ref={flatListRef}
          data={featuredWorlds}
          renderItem={renderWorldItem}
          keyExtractor={(item) => item.uuid}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.flatListContent}
          snapToAlignment="start"
          decelerationRate="fast"
          snapToInterval={WORLD_WIDTH}
          onScroll={handleScroll}
          scrollEventThrottle={16}
        />
        <TouchableOpacity style={styles.chevronContainer} onPress={scrollOneWorld}>
          <Ionicons name="chevron-forward" size={40} color={theme.colors.white} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: theme.spacings.large,
    height: WORLD_HEIGHT, // Adjust this value based on your WorldComponent height
  },
  title: {
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.yellowSecondary,
    marginBottom: theme.spacings.medium,
    paddingHorizontal: theme.spacings.medium,
    textDecorationLine: 'underline',
  },
  galleryContainer: {
    position: 'relative',
    height: '100%',
  },
  flatListContent: {
    paddingHorizontal: theme.spacings.small,
    paddingRight: theme.spacings.small + CHEVRON_CONTAINER_WIDTH, // Add extra padding for the chevron
  },
  worldItemContainer: {
    width: ORIGINAL_WORLD_WIDTH,
    marginRight: WORLD_SPACING,
  },
  loadingText: {
    color: theme.colors.beige,
    fontSize: theme.fonts.sizes.medium,
    textAlign: 'center',
    marginVertical: theme.spacings.large,
  },
  errorText: {
    color: theme.colors.redPrimary,
    fontSize: theme.fonts.sizes.medium,
    textAlign: 'center',
    marginVertical: theme.spacings.large,
  },
  chevronContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: CHEVRON_CONTAINER_WIDTH,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.partial_transparent_gray,
    borderRadius: theme.borderRadius.medium,
  },
  scrollViewContent: {
    paddingHorizontal: theme.spacings.small,
  },
});

export default FeaturedWorldsGallery;
