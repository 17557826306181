import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import theme from '../styles/theme';

const SIZES = {
  small: 24,
  medium: 35,
  large: 48,
};

const CardCountIndicator = ({ count, size = 'medium', outline = false }) => {
  // Ensure null, undefined, or NaN values return null, but allow 0 to be displayed
  if (count === null || count === undefined || Number.isNaN(count)) return null;

  const circleSize = typeof size === 'number' ? size : SIZES[size] || SIZES.medium;
  const fontSize = circleSize * 0.5;

  const containerStyle = {
    width: circleSize,
    height: circleSize,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: outline ? 'transparent' : theme.colors.redPrimary, // Transparent background when outline is true
    borderWidth: outline ? 2 : 0, // Add border when outline is true
    borderColor: outline ? theme.colors.redPrimary : 'transparent', // Border color redPrimary when outline is true
  };

  const textStyle = {
    fontSize: fontSize,
    color: outline ? theme.colors.redPrimary : theme.colors.white, // Text color redPrimary when outline is true
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <Text style={[styles.text, textStyle]}>{count}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontFamily: theme.fonts.family.bold,
    textAlign: 'center',
  },
});

export default CardCountIndicator;
