import React, {useContext} from 'react';
import { TouchableOpacity, View } from 'react-native';
import SvgSiblingButtonLeft from "../assets/images/SiblingButtonLeft";
import SvgSiblingButtonRight from "../assets/images/SiblingButtonRight";
import { useNavigation } from '@react-navigation/native';
import { useQueryClient } from 'react-query';
import { fetchCardByUUID } from "../utils/api/cardsService";
import theme from "../styles/theme";
import styles from "../styles/components/Card.styles";
import {useAudioPlayer} from "../contexts/AudioPlayerContext";
import {AuthContext} from "../contexts/AuthContext";


const SiblingNavigationButtons = ({ card }) => {
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  const { stopSound } = useAudioPlayer();
  const { isAuthenticated } = useContext(AuthContext);


  const navigateToSibling = async (siblingUuid, world_uuid) => {
    if (siblingUuid) {
      // await queryClient.fetchQuery(['cardDetail', siblingUuid], () => fetchCardByUUID(siblingUuid));
      // faster without prefetch?
      stopSound(); // make sure we stop audio playback
      let screenName;
      if (isAuthenticated) {
        screenName = "CardDetail";
      }
      else {
        screenName = "PublicCardDetail";
      }
      // navigation.navigate(screenName, { card: { uuid: siblingUuid, world_uuid: world_uuid } });
      navigation.navigate(screenName, { card_uuid: siblingUuid });
    }
  };

  if (!card) {
    return null;
  }

  return (
    <View style={styles.navigationContainer}>
      {card.prev_sibling_uuid &&
        <TouchableOpacity
          style={styles.circleButton}
          onPress={() => navigateToSibling(card.prev_sibling_uuid, card.world_uuid)}
          disabled={!card.prev_sibling_uuid}>
          <SvgSiblingButtonLeft color={theme.colors.black} />
        </TouchableOpacity>
      }
      {!card.prev_sibling_uuid && card.next_sibling_uuid && <View style={styles.placeholder} />}
      {!card.next_sibling_uuid && card.prev_sibling_uuid && <View style={styles.placeholder} />}
      {card.next_sibling_uuid &&
        <TouchableOpacity
          style={styles.circleButton}
          onPress={() => navigateToSibling(card.next_sibling_uuid, card.world_uuid)}
          disabled={!card.next_sibling_uuid}>
          <SvgSiblingButtonRight color={theme.colors.black} />
        </TouchableOpacity>
      }
    </View>
  );
};

export default SiblingNavigationButtons;
