import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { Audio } from 'expo-av';
import * as FileSystem from 'expo-file-system';
import { Platform } from 'react-native'; // Import Platform for platform detection

const AudioPlayerContext = createContext();

export const AudioPlayerProvider = ({ children }) => {
    const soundRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const setAudioMode = async () => {
            try {
                await Audio.setAudioModeAsync({
                    playsInSilentModeIOS: true,
                    staysActiveInBackground: false,
                    shouldDuckAndroid: false,
                });
            } catch (error) {
                console.error('Failed to set audio mode:', error);
            }
        };

        setAudioMode();

        return () => {
            if (soundRef.current) {
                soundRef.current.unloadAsync();
            }
        };
    }, []);

    const downloadAudio = async (audioUri) => {
        if (Platform.OS === 'web') {
            // On web, use fetch to get the audio data and create a Blob URL
            try {
                const response = await fetch(audioUri);
                const blob = await response.blob();
                const localUri = URL.createObjectURL(blob);
                return localUri;
            } catch (error) {
                console.error('Error fetching audio:', error);
                return null;
            }
        } else {
            // On native platforms, use expo-file-system
            const tempDir = FileSystem.cacheDirectory + 'audio/';
            await FileSystem.makeDirectoryAsync(tempDir, { intermediates: true });

            const localFilePath = tempDir + 'localAudio.mp3';

            try {
                // Delete existing file if it exists
                await FileSystem.deleteAsync(localFilePath, { idempotent: true });

                // Download the remote audio file to the local file system
                const { uri } = await FileSystem.downloadAsync(audioUri, localFilePath);

                return uri; // Return the local file URI
            } catch (error) {
                console.error('Error downloading audio:', error);
                return null;
            }
        }
    };

    const playTitleAndFlavor = async (audioUri, duration) => {
        try {
            setIsLoading(true);
            const localUri = await downloadAudio(audioUri);
            if (!localUri) {
                console.error('Failed to download audio');
                setIsLoading(false);
                return;
            }

            if (soundRef.current) {
                await soundRef.current.unloadAsync();
            }

            const { sound: newSound, status } = await Audio.Sound.createAsync(
                { uri: localUri },
                { shouldPlay: true },
                onPlaybackStatusUpdate
            );

            soundRef.current = newSound;
            setIsPlaying(true);
            console.log(`This is the duration: ${duration}`);
            setDuration(status.durationMillis);
            setIsLoading(false);
        } catch (error) {
            console.error('Error playing downloaded audio:', error);
            setIsLoading(false);
        }
    };

    const onPlaybackStatusUpdate = (status) => {
        if (status.isLoaded) {
            setProgress(status.positionMillis / status.durationMillis);
            setIsPlaying(status.isPlaying);

            if (status.didJustFinish) {
                setIsPlaying(false);
                setProgress(1);
                console.log('Audio finished playing');
            }
        } else if (status.error) {
            console.error(`Playback Error: ${status.error}`);
        }
    };

    const stopSound = async () => {
        try {
            if (soundRef.current) {
                await soundRef.current.stopAsync();
                await soundRef.current.unloadAsync();
                soundRef.current = null;
                setProgress(0);
                setIsPlaying(false);
            }
        } catch (error) {
            console.log('Error stopping sound:', error);
        }
    };

    const togglePlayPause = async () => {
        if (!soundRef.current) return;

        try {
            const status = await soundRef.current.getStatusAsync();
            if (status.isLoaded) {
                if (status.isPlaying) {
                    await soundRef.current.pauseAsync();
                } else {
                    await soundRef.current.playAsync();
                }
                setIsPlaying(!status.isPlaying);
            }
        } catch (error) {
            console.error('Error toggling play/pause:', error);
        }
    };

    return (
        <AudioPlayerContext.Provider
            value={{ playTitleAndFlavor, stopSound, progress, isPlaying, togglePlayPause, isLoading }}
        >
            {children}
        </AudioPlayerContext.Provider>
    );
};

export const useAudioPlayer = () => useContext(AudioPlayerContext);
