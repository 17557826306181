import React, {memo, useContext} from 'react';
import {Dimensions, TouchableOpacity, View, Text, StyleSheet } from 'react-native';
import { Image } from 'expo-image'
import UserCircle from "./UserCircle";
import {useNavigation} from '@react-navigation/native';
import theme from "../styles/theme";
import {AuthContext} from "../contexts/AuthContext";

export const windowWidth = Math.min(Dimensions.get('window').width, 500);
export const cardContainerSize = windowWidth / 3;
export const cardTileSize = cardContainerSize - 10;

const CardTile = ({ item, margin=5, showUsername = true }) => {
    const navigation = useNavigation();
    const { uuid, creator_uuid } = item;
    const { isAuthenticated } = useContext(AuthContext);

    const handlePress = () => {
      if (isAuthenticated) {
        navigation.navigate('CardDetail', { card_uuid: item.uuid });
      } else {
        navigation.navigate('PublicCardDetail', { card_uuid: item.uuid });
      }
    }

    return (
        <TouchableOpacity onPress={handlePress}>
            <View style={{ width: cardContainerSize, alignItems: 'center' }}>
                <Image
                    source={{ uri: item.thumbnail || item.art }}
                    style={{ width: cardTileSize, height: cardTileSize, margin: margin }}
                />
                {showUsername && creator_uuid && (
                  <View style={styles.username}>
                    <UserCircle
                      profile_uuid={creator_uuid}
                      size={23}
                      borderColor={theme.colors.white}
                      borderWidth={0.75}
                    />
                  </View>
                )}
            </View>
        </TouchableOpacity>
    );
};


const styles = StyleSheet.create({
    username: {
      color: theme.colors.lighterBlue,
      fontFamily: theme.fonts.family.regular,
      fontSize: theme.fonts.sizes.medium,
      margin: 1.5,
      // backgroundColor: theme.colors.gray,

      position: 'absolute',
      bottom: theme.spacings.xsmall,
      left: theme.spacings.xsmall,
    },
});

export default memo(CardTile);
