import React, {useState} from 'react';
import {StyleSheet, Text, useWindowDimensions, View} from 'react-native';
import {TabBar, TabView} from 'react-native-tab-view';
import {Ionicons} from '@expo/vector-icons';
import theme from "../styles/theme";
import useWorlds from "../hooks/useWorlds";
import ProtoWorldsGallery, { EmptyOrLoadingComponent } from "./ProtoWorldsGallery";
import BigWorldsGallery from "./BigWorldsGallery";
import useCards from "../hooks/useCards";
import API_ENDPOINTS from "../apiEndpoints";
import TimeGallery from "./TimeGallery";
import {useProfile} from "../contexts/ProfileContext";
import globalStyles from "../styles/globalStyles";
import {GetFriendsHeader} from "./ProtoWorldsGallery";
import HeaderWithLine from "./HeaderWithLine";


const TabComponent = ({
  cardsEndpoint,
  bigWorldsEndpoint,
  cardsTitle,
  bigWorldsTitle,
  initialTab,
}) => {
  const layout = useWindowDimensions();
  const initialIndex = initialTab === 'bigWorlds' ? 1 : 0;
  const [index, setIndex] = useState(initialIndex);
  const { profileData, isLoading: profileLoading }= useProfile();

  const routes = [
    { key: 'cards', title: 'Cards' },
    { key: 'bigWorlds', title: 'Big Worlds' },
  ];


  const {
    cards,
    nextPageURL,
    loadingCards,
    fetchMoreCards,
    handleRefresh,
  } = useCards(cardsEndpoint);
  const allLoading = profileLoading || loadingCards;

  const {
    worlds: bigWorlds,
    loadingWorlds: loadingBigWorlds,
    fetchMoreWorlds: fetchMoreBigWorlds,
    nextPageURL: bigWorldsNextPageURL,
    handleRefresh: refreshBigWorlds,
  } = useWorlds(bigWorldsEndpoint);

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'cards':
        return (
          <TimeGallery
            headerComponent={
              <>
                <HeaderWithLine title={cardsTitle} onRefresh={handleRefresh} loading={loadingCards} />
                <GetFriendsHeader headerComponent={<></>} hasFriends={profileData && profileData.has_friends} worlds={cards}/>
              </>
            }
            nextPageURL={nextPageURL}
            cards={cards}
            loadMore={fetchMoreCards}
            loading={loadingCards}
            onRefresh={handleRefresh}
            emptyComponent={
            <EmptyOrLoadingComponent
              loading={allLoading}
              hasFriends={profileData && profileData.has_friends}
            />
          }
          />
        );
      case 'bigWorlds':
        return (
          <BigWorldsGallery
            headerComponent={
              <HeaderWithLine title={bigWorldsTitle} onRefresh={refreshBigWorlds} loading={loadingBigWorlds}/>
            }
            worlds={bigWorlds}
            loading={loadingBigWorlds}
            loadMore={fetchMoreBigWorlds}
            nextPageURL={bigWorldsNextPageURL}
            onRefresh={refreshBigWorlds}
          />
        );
      default:
        return null;
    }
  };

  const renderTabBar = props => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: theme.colors.gray }}
      style={{ backgroundColor: theme.colors.beige }}
      renderIcon={({ route, focused, color }) => {
        const iconName =
          route.key === 'cards'
            ? focused
              ? 'grid'
              : 'grid-outline'
            : focused
            ? 'planet'
            : 'planet-outline';
        return <Ionicons name={iconName} size={24} color={color} />;
      }}
      renderLabel={({ route, focused, color }) => {
        const label = route.key === 'cards' ? cardsTitle : bigWorldsTitle;
        return (
          <Text style={{ color, fontSize: theme.fonts.sizes.medium, fontFamily: theme.fonts.family.bold, textAlign: 'center' }}>
            {label}
          </Text>
        );
      }}
      activeColor={theme.colors.redPrimary}
      inactiveColor={theme.colors.gray}
    />
  );

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width }}
      renderTabBar={renderTabBar}
    />
  );
};

const styles = StyleSheet.create({
  line: {
    width: '100%',
    alignSelf: 'center',
    height: 0.3,
    backgroundColor: theme.colors.lightgray,
  },
});


export default TabComponent;
