import React, {useContext} from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions } from 'react-native';
import { Image } from 'expo-image';
import theme from '../styles/theme';
import { useNavigation } from '@react-navigation/native';
import HorizontalCardStack from "./HorizontalCardStack";
import { Ionicons } from '@expo/vector-icons'; // Import Ionicons
import CardCountIndicator from "./CardCountIndicator";
import {AuthContext} from "../contexts/AuthContext";

export const MINI_WORLD_WIDTH = 250;
const ASPECT_RATIO = 16 / 9;
export const IMAGE_HEIGHT = MINI_WORLD_WIDTH / ASPECT_RATIO;
export const BOTTOM_ROW_HEIGHT = 30; // Adjust this value based on your actual bottom row height
export const TOTAL_WORLD_HEIGHT = IMAGE_HEIGHT + BOTTOM_ROW_HEIGHT;


const MiniWorldComponent = React.memo(({ world, disableTouch = false, handleClose=null }) => {
  const navigation = useNavigation();
  const { isAuthenticated } = useContext(AuthContext);

  // const navigateToWorldDetail = () => {
  //   navigation.navigate('World', { worldUuid: world.uuid });
  // };
  const navigateToWorldDetail = () => {
    // navigation.navigate('WorldDetail', {
    //   world: world,
    // });
  };

  return (
    <View style={styles.pressableContainer}>
      <TouchableOpacity onPress={navigateToWorldDetail} style={styles.container} disabled={disableTouch}>
        {world.art ? (
          <Image source={{uri: world.art}} style={styles.image} />
        ) : (
          <View style={styles.cardsImage}>
            <HorizontalCardStack
              cards={world.cards}
              width={MINI_WORLD_WIDTH}
              height={IMAGE_HEIGHT}
            />
          </View>
        )}
        <View style={styles.bottomRow}>
          <View style={styles.titleContainer}>
            <Text style={styles.worldName} numberOfLines={1} ellipsizeMode="tail">
              {world.name.toUpperCase()}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
      {handleClose && isAuthenticated && (
        <TouchableOpacity
          style={styles.closeButton}
          onPress={handleClose}
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
        >
          <Ionicons name="close" size={18} color={theme.colors.gray} />
        </TouchableOpacity>

      )}
    </View>
  );
});

const styles = StyleSheet.create({
  titleContainer: {
    flex: 1,
    marginRight: theme.spacings.xsmall,
  },
  worldName: {
    color: theme.colors.yellowPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
  },
  countContainer: {
    flexShrink: 0,
  },
  pressableContainer: {
    width: MINI_WORLD_WIDTH,
    height: TOTAL_WORLD_HEIGHT,
    marginHorizontal: theme.spacings.xsmall,
    position: 'relative',  // Add this to allow absolute positioning of the close button
  },
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.gray,
    borderRadius: theme.borderRadius.small,
    overflow: 'hidden',
  },
  image: {
    width: MINI_WORLD_WIDTH,
    height: IMAGE_HEIGHT,
  },
  cardsImage: {
    height: IMAGE_HEIGHT,
  },
  bottomRow: {
    height: BOTTOM_ROW_HEIGHT,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingHorizontal: theme.spacings.small,
  },
  closeButton: {
    position: 'absolute',
    top: -5,
    right: -5,
    width: 24,
    height: 24,
    borderRadius: 12,
    borderWidth: 1,
    backgroundColor: theme.colors.loadingBackground,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  closeButtonText: {
    color: theme.colors.gray,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 24,
    textAlign: 'center',
  }
});

export default MiniWorldComponent;
