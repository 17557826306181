import React from 'react';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import SearchComponent from '../components/SearchComponent';
import UsersList from '../components/lists/UsersList';
import theme from "../styles/theme";
import { fetchWorldCollaboratorsSearchResults } from '../utils/api/api';

const WorldCollaboratorsScreen = ({ route }) => {
  const { worldUuid } = route.params;

  const facets = [
    {
      key: 'collaborators',
      label: 'Collaborators',
      component: UsersList,
      fetchFunction: (query, page) => fetchWorldCollaboratorsSearchResults(worldUuid, query, page),
    },
  ];

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <SearchComponent
          facets={facets}
          initialFacetKey={'collaborators'}
          placeholder={"Search Contributors..."}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: theme.colors.beige,
  },
  container: {
    flex: 1,
    padding: 10,
  },
});

export default WorldCollaboratorsScreen;
