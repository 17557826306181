import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Image} from 'expo-image';

const HorizontalCardStack = ({ cards, maxCards = 5, width, height }) => {
  const displayCards = cards.slice(0, maxCards);
  const cardWidth = width / 2; // Each card takes up half the width

  // Safeguard against division by zero
  const cardCount = Math.max(displayCards.length, 1);
  const overlap = cardCount > 1 ? (cardWidth * cardCount - width) / (cardCount - 1) : 0;

  return (
    <View style={[styles.container, { width, height }]}>
      {displayCards.map((card, index) => (
        <Image
          key={card.uuid}
          source={{ uri: card.art }}
          style={[
            styles.cardImage,
            {
              left: index * (cardWidth - overlap),
              zIndex: displayCards.length - index,
              width: cardWidth,
            }
          ]}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    overflow: 'hidden',
  },
  cardImage: {
    position: 'absolute',
    height: '100%',
  },
});

export default HorizontalCardStack;
