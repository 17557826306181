import axios from 'axios';
import API_ENDPOINTS from '../../apiEndpoints';
import {authenticatedAxiosCall} from "./tokenService";

const fetchSearchResults = async (endpoint, query, page = 1) => {
  query = query.trim();
  const apiUrl = `${endpoint}?q=${encodeURIComponent(query)}&page=${encodeURIComponent(page)}`;

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error(`Error fetching search results from ${endpoint}:`, error);
    throw error;
  }
};


const authenticatedFetchSearchResults = async (endpoint, query, page = 1) => {
  console.log(`authenticatedFetchSearchResults: ${endpoint}, ${query}, ${page}`)
  query = query.trim();
  const apiUrl = `${endpoint}?q=${encodeURIComponent(query)}&page=${encodeURIComponent(page)}`;

  try {
    const response = await authenticatedAxiosCall("get", apiUrl);
    return response.data;
  } catch (error) {
    console.error(`Error fetching search results from ${endpoint}:`, error);
    throw error;
  }
};

const fetchUserSearchResults = (query, page) => authenticatedFetchSearchResults(API_ENDPOINTS.ALL_USERS_SEARCH, query, page);
const fetchFriendsSearchResults = (query, page) => authenticatedFetchSearchResults(API_ENDPOINTS.FRIENDS_SEARCH, query, page);
export const fetchWorldCollaboratorsSearchResults = (worldUuid, query, page) =>
  authenticatedFetchSearchResults(API_ENDPOINTS.generate.WORLD_COLLABORATORS_SEARCH(worldUuid), query, page);
const fetchBlockedUsersSearchResults = (query, page) => authenticatedFetchSearchResults(API_ENDPOINTS.BLOCKED_USERS_SEARCH, query, page);

const fetchCardSearchResults = (query, page) => authenticatedFetchSearchResults(API_ENDPOINTS.ALL_CARDS_SEARCH, query, page);
const fetchUserCardsSearchResults = (query, page) => authenticatedFetchSearchResults(API_ENDPOINTS.USER_CARDS_SEARCH, query, page);
const fetchFriendsCardsSearchResults = (query, page) => authenticatedFetchSearchResults(API_ENDPOINTS.FRIENDS_CARDS_SEARCH, query, page);


export { fetchFriendsSearchResults, fetchCardSearchResults, fetchUserSearchResults, fetchUserCardsSearchResults, fetchFriendsCardsSearchResults, fetchBlockedUsersSearchResults };
