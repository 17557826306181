import React, {useLayoutEffect} from 'react';
import {ActivityIndicator, StyleSheet, Text, View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {Image, ImageBackground} from "expo-image";
import {useWorldDetails} from '../hooks/useWorldDetails';
import TimeGallery from '../components/TimeGallery';
import theme from '../styles/theme';
import useCards from '../hooks/useCards';
import API_ENDPOINTS from '../apiEndpoints';
import {Ionicons} from "@expo/vector-icons";
import WorldComponent from "../components/WorldComponent";
import HeaderWithLine from "../components/HeaderWithLine";
import {GetFriendsHeader} from "../components/ProtoWorldsGallery";
import {HeaderBackButton} from "@react-navigation/elements";

const HeaderTitle = () => {
  return (
    <View style={styles.headerTitleContainer}>
      <Ionicons name="planet-outline" size={24} color={theme.colors.gray} />
    </View>
  );
};


const WorldScreen = ({ route }) => {
  const { worldUuid } = route.params;
  const { data: worldData, isLoading: worldLoading, error: worldError } = useWorldDetails(worldUuid);
  const navigation = useNavigation();

  const {
    cards,
    nextPageURL,
    loadingCards,
    fetchMoreCards,
    handleRefresh,
  } = useCards(API_ENDPOINTS.generate.WORLD_CARDS(worldUuid));

  useLayoutEffect(() => {
    navigation.setOptions({
      headerStyle: {
        backgroundColor: theme.colors.beige,
        shadowColor: 'transparent',
        elevation: 0,
        borderBottomWidth: 0,
      },
      headerTitle: () => <HeaderTitle />,
      headerTitleStyle: {
        fontFamily: theme.fonts.family.medium,
        fontSize: theme.fonts.sizes.large,
      },
    });
  }, [navigation]);

  if (worldLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={theme.colors.gray} />
      </View>
    );
  }

  if (worldError) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>Error loading world details</Text>
      </View>
    );
  }

  const title = `CARDS FROM\n"${worldData.world?.name.toUpperCase()}"`

  return (
    <ImageBackground source={{uri: worldData.world.art}} style={styles.mainContainer} imageStyle={{ opacity: 0.15 }}>
    {/*<View style={styles.mainContainer}>*/}
      <TimeGallery
        headerComponent={
          <HeaderWithLine title={title} onRefresh={handleRefresh} loading={loadingCards}/>
        }
        cards={cards}
        nextPageURL={nextPageURL}
        loadMore={fetchMoreCards}
        loading={loadingCards}
        onRefresh={handleRefresh}
        refreshIndicatorColor={theme.colors.beige}
      />
    {/*</View>*/}
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: theme.colors.beige,
    flex: 1,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.beige,
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.beige,
  },
  errorText: {
    color: theme.colors.redPrimary,
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.regular,
  },
  headerTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTitleText: {
    // color: theme.colors.white,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.large,
    marginRight: theme.spacings.xsmall, // Adjust spacing between icon and text
  },
});

export default WorldScreen;
