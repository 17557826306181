import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import UserCircle from './UserCircle';
import theme from '../styles/theme';

const OVERLAP = 10
const DISPLAY_CONTRIBUTORS_MAX = 4 // Same value in world serializer

const ContributorsList = ({ contributors, additional_contributors_count= 0, size = 30, creatorUuid = null }) => {
  const filteredContributors = contributors.filter(c => c.uuid !== creatorUuid);

  let additionalContributorsText = '';
  if (additional_contributors_count > 0) {
    additionalContributorsText = `+${additional_contributors_count}`;
  }

  return (
    <View style={styles.container}>
      {filteredContributors.slice(0, DISPLAY_CONTRIBUTORS_MAX).map((contributor, index) => (
        <View key={contributor.uuid} style={[styles.contributorCircle, { zIndex: 4 - index }]}>
          <UserCircle profile_uuid={contributor.uuid} size={size} borderColor={theme.colors.beige} />
        </View>
      ))}
      {additional_contributors_count > 0 && (
        <View style={[styles.contributorCircle, styles.moreCollaborators, { width: size, height: size, borderRadius: size / 2 }]}>
          <Text style={styles.moreCollaboratorsText}>{additionalContributorsText}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: OVERLAP,
  },
  contributorCircle: {
    marginLeft: -OVERLAP,
  },
  moreCollaborators: {
    backgroundColor: theme.colors.yellowPrimary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  moreCollaboratorsText: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.small,
    fontWeight: 'bold',
  },
});

export default ContributorsList;
