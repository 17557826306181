import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import globalStyles from "../styles/globalStyles";
import theme from "../styles/theme";
import RefreshButton from "./buttons/RefreshButton";

const HeaderWithLine = ({ title, onRefresh, loading }) => (
  <View>
    <View style={styles.headerContainer}>
      {/*<Text style={[globalStyles.bigGalleryTitle, styles.title]}>{title}</Text>*/}
      <View style={styles.refreshButtonContainer}>
        <RefreshButton onRefresh={onRefresh} loading={loading} />
      </View>
    </View>
    <View style={styles.line} />
  </View>
);

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.spacings.small,
    position: 'relative',
    minHeight: 50,
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  refreshButtonContainer: {
    // position: 'absolute',
    // right: theme.spacings.small,
    // top: 0,
    // bottom: 0,
    justifyContent: 'center',
  },
  line: {
    width: '100%',
    alignSelf: 'center',
    height: 0.3,
    backgroundColor: theme.colors.lightgray,
    marginTop: theme.spacings.xsmall,
  },
});

export default HeaderWithLine;
