import React from 'react';
import { TouchableOpacity, Text, StyleSheet, View } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import theme from "../../styles/theme";
import {WORLD_WIDTH} from "../WorldComponent";
import SvgRiff from "../../assets/images/Riff";

const CreateButton = ({ onPress, text, icon = 'plus', iconColor = theme.colors.redPrimary, style, createType="create" }) => {
  return (
    <TouchableOpacity style={[styles.button, style]} onPress={onPress}>
      {createType === "create" ? (
        <FontAwesome name={icon} size={24} color={iconColor} style={styles.icon} />
      ) : (
        <View style={styles.iconWrapper}>
          <SvgRiff style={styles.icon} wandColor={theme.colors.gray} />
        </View>
      )}
      <Text style={styles.buttonText}>{text}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    alignSelf: "center",
    // Shadow
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,

    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: theme.borderRadius.large,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.gray,
    width: WORLD_WIDTH,
    paddingHorizontal: 20,
    paddingVertical: 15,
    position: 'relative',
    marginVertical: theme.spacings.medium,
  },
  buttonText: {
    color: theme.colors.redPrimary,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    textAlign: 'center',
    flex: 1,
  },
  icon: {
    width: 24, // Ensures the SVG has a defined width
    height: 24, // Ensures the SVG has a defined height
  },
  iconWrapper: {
    position: 'absolute',
    left: 20,
    width: 30, // Wrapper width (should be slightly larger than the icon)
    height: 30, // Wrapper height (should be slightly larger than the icon)
    borderRadius: 16, // Half of the width/height to make it a circle
    borderWidth: 2, // Border width
    borderColor: theme.colors.gray, // Border color
    backgroundColor: theme.colors.gray, // Background
    justifyContent: 'center', // Center the SVG horizontally
    alignItems: 'center', // Center the SVG vertically
  },
});

export default CreateButton;
