import React, {memo, useContext} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {MaterialIcons} from '@expo/vector-icons';
import theme from "../styles/theme";
import {HEADER_BUTTON_SIZE} from "../styles/globalStyles";
import {fetchUnreadNotificationCount} from "../utils/api/userService";
import {useQuery} from 'react-query';
import {AuthContext} from "../contexts/AuthContext";

const NotificationsButtonComponent = ({navigation, isActive = false, iconColor = theme.colors.gray}) => {
  const { isAuthenticated } = useContext(AuthContext);

  const {
    data,
    refetch
  } = useQuery('unreadNotificationsCount', fetchUnreadNotificationCount, {
    refetchInterval: 30000, // Poll every 30 seconds
    enabled: isAuthenticated,
  });
  const unreadCount = data ? (data.unread_count > 99 ? '99+' : data.unread_count.toString()) : '0';

  return (
    <TouchableOpacity
      onPress={() => navigation.navigate("Notifications")}
      style={styles.headerButton}
    >
      <MaterialIcons name={isActive ? "mail" : "mail-outline"} size={HEADER_BUTTON_SIZE} color={iconColor} />
      {isAuthenticated && unreadCount > 0 && (
        <View style={styles.unreadBadge}>
          <Text
            style={styles.unreadText}
            adjustsFontSizeToFit={true}
            numberOfLines={1}
          >
            {/*{unreadCount > 99 ? '99+' : unreadCount}*/}
            {unreadCount}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  headerButton: {
    position: 'relative',
  },
  unreadBadge: {
    position: 'absolute',
    right: -2,
    top: 1,
    backgroundColor: 'red',
    borderRadius: 8,
    width: 14,
    height: 14,
    justifyContent: 'center',
    alignItems: 'center',
  },
  unreadText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.xsmall,
    fontFamily: theme.fonts.family.bold,
    padding: 1,
  },
});

const NotificationsButton = memo(NotificationsButtonComponent);
export default NotificationsButton;
