// HorizontalMiniCardGallerySkeleton.js
import React from 'react';
import { ScrollView, View, StyleSheet, useWindowDimensions } from 'react-native';
import MiniCardSkeleton from './MiniCardSkeleton'; // Adjust the path as necessary
import { cardContainerSize } from "../CardTile"; // Ensure cardContainerSize is exported from CardTile
import theme from '../../styles/theme';

const ROWS = 3; // Match the number of rows in your gallery

const HorizontalMiniCardGallerySkeleton = () => {
  const { width } = useWindowDimensions();
  const numColumns = Math.floor(width / cardContainerSize);

  // Generate skeletons for each row
  const skeletonRows = Array.from({ length: ROWS }).map((_, rowIndex) => (
    <View key={`skeleton-row-${rowIndex}`} style={styles.row}>
      {Array.from({ length: numColumns }).map((_, colIndex) => (
        <MiniCardSkeleton key={`skeleton-${rowIndex}-${colIndex}`} />
      ))}
    </View>
  ));

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={styles.scrollView}
    >
      <View style={styles.container}>
        {skeletonRows}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  container: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    marginVertical: theme.spacings.xsmall,
  },
});

export default HorizontalMiniCardGallerySkeleton;
