import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity, StyleSheet, ScrollView} from 'react-native';
import {useRoute} from '@react-navigation/native';
import {useQuery, useMutation} from 'react-query';
import {fetchWorld, fetchWorldOwner, updateWorldSettings} from "../utils/api/cardsService";
import theme from '../styles/theme';
import ChooseFriendModal from '../components/modals/ChooseFriendModal';
import UserCircle from '../components/UserCircle';
import {MaterialIcons} from "@expo/vector-icons";
import SvgRiff from "../assets/images/Riff";
import { useSubscription } from '../hooks/useSubscription';


const WorldSettingsScreen = () => {
  const route = useRoute();
  const {worldUUID} = route.params;

  const {
    data,
    error,
    isLoading
  } = useQuery(['world', worldUUID], () => fetchWorld(worldUUID));
  const [visibility, setVisibility] = useState(data?.world?.visibility || 'public');
  const [contributionSetting, setContributionSetting] = useState(data?.world?.contribution_setting || 'friends');
  const [friends, setFriends] = useState([]);
  const [contributors, setContributors] = useState([]);
  const { data: ownerData, isLoading: isOwnerLoading } = useQuery(['worldOwner', worldUUID], () => fetchWorldOwner(worldUUID));
  const [isVisibilityModalVisible, setVisibilityModalVisible] = useState(false);
  const [isContributionModalVisible, setContributionModalVisible] = useState(false);
  const [visibilityMessage, setVisibilityMessage] = useState('');
  const [contributionMessage, setContributionMessage] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { data: subscriptionData, isLoading: isSubscriptionLoading } = useSubscription();
  // log the subscription data
  const isOwner = ownerData?.is_owner;

  const visibilityOptions = [
    {label: "Public", value: "public"},
    {label: "Friends +", value: "friends"},
    {label: "Contributors", value: "private"},
    // { label: "Custom", value: "custom" }
  ];

  const contributionOptions = [
    {label: "Public", value: "public"},
    {label: "Friends +", value: "friends"},
    {label: "Contributors", value: "private"},
    // { label: "Custom", value: "custom" }
  ];

  const isPremiumVisibility = (value) => {
    if (!subscriptionData?.platform) {
      return value !== 'public';
    }
    return false;
  };

  const isPremiumContribution = (value) => {
    if (!subscriptionData?.platform) {
      return value === 'private' || value === 'custom';
    }
    return false;
  };

  const mutation = useMutation(({
                                  visibility,
                                  contributionSetting,
                                  friends,
                                  contributors
                                }) =>
    updateWorldSettings(worldUUID, visibility, contributionSetting, friends, contributors)
  );

  useEffect(() => {
    if (data?.world) {
      setVisibility(data.world.visibility);
      setContributionSetting(data.world.contribution_setting);
      setFriends(data.world.allowed_users || []);
      setContributors(data.world.allowed_users_contribute || []);
    }
  }, [data]);

  useEffect(() => {
    setVisibilityMessage(getVisibilityMessage(visibility));
    setContributionMessage(getContributionMessage(contributionSetting));
  }, [visibility, contributionSetting]);

  const handleVisibilityChange = (newVisibility) => {
    setVisibility(newVisibility);
    setUnsavedChanges(true);

    // Adjust contribution setting if it is more open than the new visibility setting
    if (newVisibility === 'private' && contributionSetting !== 'private') {
      setContributionSetting('private');
    } else if (newVisibility === 'friends' && contributionSetting === 'public') {
      setContributionSetting('friends');
    } else if (newVisibility === 'custom' && (contributionSetting === 'public' || contributionSetting === 'friends')) {
      setContributionSetting('custom');
    }
  };

  const handleContributionChange = (newSetting) => {
    setContributionSetting(newSetting);
    setUnsavedChanges(true);
  };

  const isContributionDisabled = (value) => {
    if (!subscriptionData?.platform) {
      return value !== 'public' && value !== 'friends';  // Allow public and friends only for non-subscribers
    }

    if (visibility === 'private') {
      return value !== 'private';
    }
    if (visibility === 'friends') {
      return value === 'public' || value === 'custom';
    }
    return false;
  };

  const handleSelectFriend = (friend) => {
    setFriends((prev) => {
      if (!prev.some(f => f.uuid === friend.uuid)) {
        setUnsavedChanges(true);
        return [...prev, friend];
      }
      return prev;
    });
    setVisibilityModalVisible(false);
  };

  const handleRemoveFriend = (friendUUID) => {
    setFriends(friends.filter(friend => friend.uuid !== friendUUID));
    setUnsavedChanges(true);
  };

  const handleSelectContributor = (contributor) => {
    setContributors((prev) => {
      if (!prev.some(c => c.uuid === contributor.uuid)) {
        setUnsavedChanges(true);
        return [...prev, contributor];
      }
      return prev;
    });
    setContributionModalVisible(false);
  };

  const handleRemoveContributor = (contributorUUID) => {
    setContributors(contributors.filter(contributor => contributor.uuid !== contributorUUID));
    setUnsavedChanges(true);
  };

  const handleSaveSettings = () => {
    mutation.mutate({
      visibility,
      contributionSetting,
      friends: friends.map(friend => friend.uuid),
      contributors: contributors.map(contributor => contributor.uuid)
    });
    setUnsavedChanges(false);
  };

  // if (isLoading) return <Text>Loading...</Text>;
  if (error) return <Text>Error: {error.message}</Text>;

  return (
    <View style={{flex: 1, maxWidth: 600, alignSelf: "center"}}>
      <View
        style={[
          styles.banner,
          !isOwner ? {backgroundColor: theme.colors.gray} : (unsavedChanges ? styles.unsavedChangesBanner : styles.upToDateBanner)
        ]}
      >
        <Text style={[styles.bannerText, {flexWrap: 'wrap', textAlign: 'center'}]}>
          {!isOwner
            ? "Only world owner can edit."
            : (unsavedChanges ? "You have unsaved changes" : "All changes are up to date")}
        </Text>
      </View>
      <ScrollView
        style={[styles.container, {marginTop: theme.fonts.sizes.medium * 2}]}>
        <Text style={styles.worldOwnerDescription}>
          {isOwner
            ? "As the world originator, you can modify settings for this world."
            : "The world owner is the creator of the earliest card in this world."
          }
        </Text>
        <View style={styles.headingContainer}>
          <MaterialIcons name="visibility" size={24} color={theme.colors.gray}/>
          <Text style={styles.title}>VISIBILITY SETTINGS</Text>
        </View>
        <Text style={styles.description}>
          Who can discover<Text style={styles.superscript}>*</Text> this world?
        </Text>
        <View style={styles.radioGroup}>
          {visibilityOptions.map(option => (
            <RadioButton
              key={option.value}
              label={option.label}
              value={option.value}
              selectedValue={visibility}
              onPress={handleVisibilityChange}
              // disabled={isPremiumVisibility(option.value)}
              // premium={isPremiumVisibility(option.value)}
              disabled={!isOwner || !subscriptionData?.platform && option.value !== 'public'}
              premium={!subscriptionData?.platform && option.value !== 'public'}
            />
          ))}
        </View>
        <Text style={styles.visibilityMessage}>{visibilityMessage}</Text>
        {visibility === 'custom' && (
          <View style={styles.customVisibilityContainer}>
            <Text style={styles.selectedFriendsHeading}>SELECTED FRIENDS:</Text>
            <TouchableOpacity onPress={() => setVisibilityModalVisible(true)}
                              style={styles.addButton}>
              <Text style={styles.addButtonText}>Add Friend</Text>
            </TouchableOpacity>
            <ScrollView style={styles.scrollView}>
              {friends.map(item => (
                <View key={item.uuid} style={styles.friendItem}>
                  <UserCircle profile_uuid={item.uuid} size={40}/>
                  <Text style={styles.friendUsername}>{item.preferred_username}</Text>
                  <TouchableOpacity onPress={() => handleRemoveFriend(item.uuid)}>
                    <Text style={styles.removeButton}>Remove</Text>
                  </TouchableOpacity>
                </View>
              ))}
            </ScrollView>
          </View>
        )}

        {/*add a horizontal line*/}
        <View style={{borderBottomColor: theme.colors.gray, borderBottomWidth: 0, marginTop: theme.spacings.medium, marginBottom: theme.spacings.medium}} />

        <View style={styles.headingContainer}>
          <SvgRiff style={{transform: [{scale: 0.8}]}} wandColor={theme.colors.gray}/>
          <Text style={styles.title}>CONTRIBUTION SETTINGS</Text>
        </View>
        <Text style={styles.description}>
          Who can riff on cards in this world?
        </Text>
        <View style={styles.radioGroup}>
          {contributionOptions.map(option => (
            <RadioButton
              key={option.value}
              label={option.label}
              value={option.value}
              selectedValue={contributionSetting}
              onPress={handleContributionChange}
              // disabled={isPremiumContribution(option.value)}
              // premium={isPremiumContribution(option.value)}
              disabled={!isOwner || isContributionDisabled(option.value)}
              premium={!subscriptionData?.platform && option.value !== 'friends' && option.value !== 'public'}
            />
          ))}
        </View>
        <Text style={styles.visibilityMessage}>{contributionMessage}</Text>
        <Text style={styles.visibilityNote}>
          * Visibility affects whether users see items in a feed or search. If
          someone has a direct link, they will still be able to access it.
        </Text>
        {contributionSetting === 'custom' && (
          <View style={styles.customVisibilityContainer}>
            <Text style={styles.selectedFriendsHeading}>SELECTED CONTRIBUTORS:</Text>
            <TouchableOpacity onPress={() => setContributionModalVisible(true)}
                              style={styles.addButton}>
              <Text style={styles.addButtonText}>Add Contributor</Text>
            </TouchableOpacity>
            <ScrollView style={styles.scrollView}>
              {contributors.map(item => (
                <View key={item.uuid} style={styles.friendItem}>
                  <UserCircle profile_uuid={item.uuid} size={40}/>
                  <Text style={styles.friendUsername}>{item.preferred_username}</Text>
                  <TouchableOpacity onPress={() => handleRemoveContributor(item.uuid)}>
                    <Text style={styles.removeButton}>Remove</Text>
                  </TouchableOpacity>
                </View>
              ))}
            </ScrollView>
          </View>
        )}
        {/*<TouchableOpacity onPress={handleSaveSettings} style={styles.saveButton}>*/}
        {/*  <Text style={styles.saveButtonText}>Save</Text>*/}
        {/*</TouchableOpacity>*/}
        <TouchableOpacity
          onPress={handleSaveSettings}
          style={[styles.saveButton, !isOwner && styles.disabledSaveButton]}
          disabled={!isOwner}
        >
          <Text style={styles.saveButtonText}>Save</Text>
        </TouchableOpacity>
        <ChooseFriendModal
          visible={isVisibilityModalVisible}
          onClose={() => setVisibilityModalVisible(false)}
          onSelectFriend={handleSelectFriend}
          headerTitle="Who do you want to share this world with?"
        />
        <ChooseFriendModal
          visible={isContributionModalVisible}
          onClose={() => setContributionModalVisible(false)}
          onSelectFriend={handleSelectContributor}
          headerTitle="Who do you want to allow to contribute to this world?"
        />
      </ScrollView>

    </View>
  );
};

const getVisibilityMessage = (visibility) => {
  switch (visibility) {
    case 'public':
      return <Text>Anyone can <Text style={styles.boldText}>discover</Text><Text style={styles.superscript}>*</Text> this
        world.</Text>;
    case 'friends':
      return <Text>Only friends of contributors can <Text
        style={styles.boldText}>discover</Text><Text style={styles.superscript}>*</Text> this world.</Text>;
    case 'private':
      return <Text>Only contributors can <Text style={styles.boldText}>discover</Text><Text style={styles.superscript}>*</Text> this
        world.</Text>;
    case 'custom':
      return <Text>Only selected friends can <Text
        style={styles.boldText}>view</Text> this world.</Text>;
    default:
      return '';
  }
};


const getContributionMessage = (contributionSetting) => {
  switch (contributionSetting) {
    case 'public':
      return <Text>Anyone can <Text style={styles.boldText}>contribute</Text> to this
        world.</Text>;
    case 'friends':
      return <Text>Only friends of contributors can <Text
        style={styles.boldText}>contribute</Text> to this world.</Text>;
    case 'private':
      return <Text>Only contributors can <Text
        style={styles.boldText}>contribute</Text> to this world.</Text>;
    case 'custom':
      return <Text>Only selected contributors can <Text
        style={styles.boldText}>contribute</Text> to this world.</Text>;
    default:
      return '';
  }
};


const RadioButton = ({ label, value, selectedValue, onPress, disabled, premium }) => {
  return (
    <View style={{flexDirection: "row"}}>
    <TouchableOpacity
      style={[styles.radioButton, disabled && { opacity: 0.5 }]}
      onPress={() => !disabled && onPress(value)}
      disabled={disabled}
    >
      <View
        style={[styles.radioCircle, selectedValue === value && styles.selectedRadioCircle]}>
        {selectedValue === value && <View style={styles.selectedInnerCircle} />}
      </View>
      <Text style={styles.radioLabel}>{label}
      </Text>
    </TouchableOpacity>
    {premium && <Text style={styles.premiumText}> PREMIUM</Text>}
    </View>
  );
};




const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: theme.spacings.medium,
    backgroundColor: theme.colors.beige,
    paddingBottom: theme.spacings.large,
  },
  premiumText: {
    color: theme.colors.yellowPrimary,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.medium,
    marginLeft: theme.spacings.small,
    marginBottom: theme.spacings.small,
  },
  title: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    marginBottom: theme.spacings.medium,
    marginLeft: theme.spacings.small,
  },
  description: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.gray,
    marginBottom: theme.spacings.medium,
  },
  visibilityMessage: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.medium,
    color: theme.colors.bluePrimary,
    marginBottom: theme.spacings.medium,
    minHeight: 40,
  },
  radioGroup: {
    marginBottom: theme.spacings.large,
  },
  radioButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacings.small,
  },
  radioCircle: {
    height: 24,
    width: 24,
    borderRadius: 12,
    borderWidth: 2,
    borderColor: theme.colors.gray,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacings.small,
  },
  selectedRadioCircle: {
    borderColor: theme.colors.bluePrimary,
  },
  selectedInnerCircle: {
    height: 12,
    width: 12,
    borderRadius: 6,
    backgroundColor: theme.colors.bluePrimary,
  },
  radioLabel: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.regular,
  },
  customVisibilityContainer: {
    marginTop: theme.spacings.medium,
  },
  friendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: theme.spacings.small,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.colors.gray,
  },
  friendUsername: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.medium,
    marginLeft: theme.spacings.small,
    color: theme.colors.bluePrimary,
    flex: 1,
  },
  removeButton: {
    color: theme.colors.redPrimary,
    fontSize: theme.fonts.sizes.small,
    fontFamily: theme.fonts.family.medium,
  },
  addButton: {
    marginBottom: theme.spacings.small,
    paddingVertical: theme.spacings.small,
    backgroundColor: theme.colors.bluePrimary,
    alignItems: 'center',
    borderRadius: theme.borderRadius.small,
  },
  addButtonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
    textTransform: 'uppercase',
  },
  saveButton: {
    marginTop: theme.spacings.medium,
    paddingVertical: theme.spacings.small,
    backgroundColor: theme.colors.bluePrimary,
    alignItems: 'center',
    borderRadius: theme.borderRadius.small,
    marginBottom: theme.spacings.xlarge,
  },
  saveButtonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    textTransform: 'uppercase',
  },
  disabledSaveButton: {
    backgroundColor: theme.colors.gray, // Adjust the color as needed
    opacity: 0.5,
  },
  selectedFriendsHeading: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.medium,
    color: theme.colors.gray,
    marginBottom: theme.spacings.small,
  },
  headingContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  scrollView: {
    maxHeight: 200,
  },
  banner: {
    position: 'absolute',
    top: 0,
    width: '100%',
    padding: theme.spacings.small,
    alignItems: 'center',
    height: theme.fonts.sizes.medium * 2,
  },
  unsavedChangesBanner: {
    backgroundColor: theme.colors.redPrimary,
  },
  upToDateBanner: {
    backgroundColor: theme.colors.bluePrimary,
  },
  bannerText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
    textTransform: 'uppercase',
  },
  boldText: {
    fontFamily: theme.fonts.family.bold,
    textDecorationLine: 'underline',
  },
  worldOwnerDescription: {
    fontSize: theme.fonts.sizes.large,
    marginBottom: theme.spacings.medium,
    width: '100%',
    alignSelf: 'center',
  },
  superscript: {
    fontSize: theme.fonts.sizes.small,
    lineHeight: theme.fonts.sizes.medium,
    verticalAlign: 'super',
  },
  visibilityNote: {
    fontSize: theme.fonts.sizes.small,
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.gray,
    marginBottom: theme.spacings.medium,
    marginTop: -theme.spacings.small,
  },
});

export default WorldSettingsScreen;
