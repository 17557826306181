import React, { useContext, useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Platform, Animated } from 'react-native';
import { Image } from 'expo-image';
import UserCircle from './UserCircle';
import ContributorsList from './ContributorsList';
import theme from '../styles/theme';
import { useNavigation } from '@react-navigation/native';
import HorizontalCardStack from "./HorizontalCardStack";
import CardCountIndicator from "./CardCountIndicator";
import { AuthContext } from "../contexts/AuthContext";

const windowWidth = Dimensions.get('window').width;
export const WORLD_WIDTH = Math.min(windowWidth - 20, 400);
export const WORLD_HEIGHT = WORLD_WIDTH;
const ASPECT_RATIO = 16 / 9;
const IMAGE_HEIGHT = WORLD_WIDTH / ASPECT_RATIO;

const WorldComponent = ({ world, disableTouch = false, showBanner = false }) => {
  const navigation = useNavigation();
  const { isAuthenticated } = useContext(AuthContext);
  const [isHovered, setIsHovered] = useState(false);
  const scaleValue = new Animated.Value(1);

  const handleNavigation = () => {
    if (world.root_card) {
      if (isAuthenticated) {
        navigation.navigate('Authenticated', {
          screen: 'Tabs',
          params: {
            screen: 'Home',
            params: {
              screen: 'CardDetail',
              params: { card_uuid: world.root_card.uuid, startAtTop: true },
            },
          },
        });
      } else {
        navigation.navigate('PublicCardDetail', { card_uuid: world.root_card.uuid, startAtTop: true });
      }
    } else {
      // If there's no root card, navigate to the WorldDetail screen as before
      if (isAuthenticated) {
        navigation.navigate('WorldDetail', { world_uuid: world.uuid });
      } else {
        navigation.navigate('PublicWorldDetail', { world_uuid: world.uuid });
      }
    }
  };

  const handleHoverIn = () => {
    setIsHovered(true);
    Animated.spring(scaleValue, {
      toValue: 1.05,
      friction: 3,
      tension: 40,
      useNativeDriver: true,
    }).start();
  };

  const handleHoverOut = () => {
    setIsHovered(false);
    Animated.spring(scaleValue, {
      toValue: 1,
      friction: 3,
      tension: 40,
      useNativeDriver: true,
    }).start();
  };

  return (
    <Animated.View style={[
      styles.animatedContainer,
      { transform: [{ scale: scaleValue }] }
    ]}>
      <TouchableOpacity
        onPress={handleNavigation}
        style={styles.pressableContainer}
        disabled={disableTouch}
        {...(Platform.OS === 'web' ? {
          onMouseEnter: handleHoverIn,
          onMouseLeave: handleHoverOut,
        } : {})}
      >
        <View style={styles.container}>
          {world.art ? (
            <Image source={{uri: world.art}} style={styles.image} />
          ) : (
            <View style={styles.cardsImage}>
              <HorizontalCardStack
                cards={world.cards}
                width={WORLD_WIDTH}
                height={IMAGE_HEIGHT}
              />
            </View>
          )}
          <View style={styles.content}>
            <Text style={styles.worldName} numberOfLines={1} ellipsizeMode="tail">
              {world.name.toUpperCase()}
            </Text>
            <Text style={styles.summary} numberOfLines={4} ellipsizeMode="tail">
              {world.kernel_or_summary}
            </Text>
            <View style={styles.bottomRow}>
              <View style={styles.creatorAndContributors}>
                {world?.creator?.uuid && (
                  <View style={styles.creatorCircle}>
                    <UserCircle profile_uuid={world.creator.uuid} size={40} borderColor={theme.colors.white} />
                  </View>
                )}
                <ContributorsList
                  contributors={world.contributors}
                  additional_contributors_count={world.additional_contributors_count}
                  size={35}
                  creatorUuid={world?.creator?.uuid}
                />
              </View>
              <CardCountIndicator count={world.total_cards} size="medium" />
            </View>
          </View>
          {showBanner && (
            <View style={[styles.banner, isHovered && styles.bannerHovered]}>
              <Text style={[styles.bannerText, isHovered && styles.bannerTextHovered]}>
                EXPLORE & CREATE
              </Text>
            </View>
          )}
        </View>
      </TouchableOpacity>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  animatedContainer: {
    width: WORLD_WIDTH,
    height: WORLD_HEIGHT,
    marginHorizontal: theme.spacings.small,
    marginBottom: theme.spacings.medium,
  },
  pressableContainer: {
    width: '100%',
    height: '100%',
  },
  container: {
    backgroundColor: theme.colors.gray,
    borderRadius: theme.borderRadius.medium,
    overflow: 'hidden',
    height: '100%',
  },
  image: {
    width: WORLD_WIDTH,
    height: IMAGE_HEIGHT,
    resizeMode: 'cover',
  },
  cardsImage: {
    height: IMAGE_HEIGHT,
  },
  content: {
    padding: theme.spacings.small,
    flex: 1,
    justifyContent: 'space-between',
  },
  worldName: {
    color: theme.colors.yellowPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
    marginBottom: theme.spacings.xxsmall,
  },
  summary: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.small,
    marginBottom: theme.spacings.small,
  },
  bottomRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  creatorAndContributors: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  creatorCircle: {
    zIndex: 5,
    marginRight: -10,
  },
  banner: {
    position: 'absolute',
    top: 0,
    // right: WORLD_WIDTH / 2,
    // backgroundColor: theme.colors.redPrimary,
    backgroundColor: theme.colors.yellowPrimary,
    borderBottomRightRadius: theme.borderRadius.medium,
    paddingHorizontal: theme.spacings.medium,
    paddingVertical: theme.spacings.small,
    transform: [{ rotate: '0deg' }],
    transition: 'all 0.3s ease',
    opacity: 0.0,
  },
  bannerHovered: {
    backgroundColor: theme.colors.yellowPrimary,
    opacity: 1.0,
  },
  bannerText: {
    // color: theme.colors.white,
    color: theme.colors.gray,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.large,
  },
  bannerTextHovered: {
    color: theme.colors.gray,
  },
});

export default React.memo(WorldComponent);
