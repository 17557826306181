import React, { useState } from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  useWindowDimensions,
  ImageBackground,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TextInput,
  Text,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import { TabView, TabBar } from 'react-native-tab-view';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import theme from '../styles/theme';
import { createWorld } from '../utils/api/cardsService';
import { useHeaderHeight } from '@react-navigation/elements';

const { width: SCREEN_WIDTH } = Dimensions.get('window');

const MAX_DESCRIPTION_LENGTH = 2000;

const NameWorldScreen = ({ worldData, setWorldData }) => (
  <KeyboardAvoidingView
    behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    style={styles.keyboardAvoidingView}
  >
    <ScrollView
      contentContainerStyle={styles.sceneContainer}
      keyboardShouldPersistTaps="handled"
    >
      <Text style={styles.stepTitle}>NAME YOUR WORLD</Text>
      <TextInput
        style={styles.nameInput}
        value={worldData.name}
        onChangeText={(text) => setWorldData({ ...worldData, name: text.toUpperCase() })}
        placeholder="ENTER WORLD NAME"
        placeholderTextColor={theme.colors.yellowPrimary}
        autoCapitalize="characters"
        autoCorrect={false}
      />
    </ScrollView>
  </KeyboardAvoidingView>
);

const DescribeWorldScreen = ({ worldData, setWorldData }) => {
  const height = useHeaderHeight();

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.keyboardAvoidingView}
      keyboardVerticalOffset={height + 47}
    >
      <View style={styles.sceneContainer}>
        <Text style={styles.stepTitle}>DESCRIBE YOUR WORLD</Text>
        <ScrollView keyboardShouldPersistTaps="handled">
          <TextInput
            style={styles.descriptionInput}
            multiline
            numberOfLines={3}
            maxLength={MAX_DESCRIPTION_LENGTH}
            value={worldData.description}
            onChangeText={(text) => setWorldData({ ...worldData, description: text })}
            placeholder="Paste or type a detailed description of your world..."
            placeholderTextColor={theme.colors.white}
          />
          <Text style={styles.charCounter}>
            {worldData.description.length}/{MAX_DESCRIPTION_LENGTH} characters
          </Text>
        </ScrollView>
      </View>
    </KeyboardAvoidingView>
  );
};

const ReviewWorldScreen = ({ worldData, handleFinalize }) => {
  const isFormValid =
    worldData.name.trim() !== '' &&
    worldData.description.trim() !== '' &&
    worldData.description.length <= MAX_DESCRIPTION_LENGTH;

  return (
    <ImageBackground style={styles.reviewBackground} imageStyle={{ opacity: 0.15 }}>
      <View style={styles.reviewContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.reviewTitle}>{worldData.name}</Text>
        </View>
        <ScrollView
          style={styles.kernelContainer}
          contentContainerStyle={styles.kernelContentContainer}
        >
          <Text style={styles.reviewDescription}>{worldData.description}</Text>
        </ScrollView>
        <TouchableOpacity
          style={[
            styles.finalizeButton,
            !isFormValid && styles.finalizeButtonDisabled,
          ]}
          onPress={handleFinalize}
          disabled={!isFormValid}
        >
          <Text style={styles.finalizeButtonText}>
            {isFormValid ? 'CREATE WORLD' : 'PLEASE COMPLETE ALL FIELDS'}
          </Text>
        </TouchableOpacity>
      </View>
    </ImageBackground>
  );
};

const WorldCreationFlow = () => {
  const layout = useWindowDimensions();
  const navigation = useNavigation();
  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'name', title: 'Name', icon: 'text' },
    { key: 'describe', title: 'Describe', icon: 'pencil' },
    { key: 'review', title: 'Review', icon: 'checkmark-circle' },
  ]);
  const [worldData, setWorldData] = useState({
    name: '',
    description: '',
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleFinalize = async () => {
    if (
      !worldData.name.trim() ||
      !worldData.description.trim() ||
      worldData.description.length > MAX_DESCRIPTION_LENGTH
    ) {
      alert(
        'Validation Error',
        'World name cannot be empty and description must be less than 1500 characters!'
      );
      return;
    }
    setIsCreating(true);
    try {
      const { task_id, uuid } = await createWorld(worldData.name, worldData.description);
      navigation.navigate('WorldCreation', {
        worldName: worldData.name,
        worldDescription: worldData.description,
        task_id: task_id,
        uuid: uuid,
      });
    } catch (error) {
      setIsCreating(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.code === 'INSUFFICIENT_GENERATIONS'
      ) {
        console.log('Insufficient generations');
      } else {
        alert('Error', error.message || 'An error occurred. Please try again.');
      }
    }
  };

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'name':
        return <NameWorldScreen worldData={worldData} setWorldData={setWorldData} />;
      case 'describe':
        return <DescribeWorldScreen worldData={worldData} setWorldData={setWorldData} />;
      case 'review':
        return (
          <ReviewWorldScreen worldData={worldData} handleFinalize={handleFinalize} />
        );
      default:
        return null;
    }
  };

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: theme.colors.yellowPrimary }}
      style={{ backgroundColor: theme.colors.gray }}
      renderIcon={({ route, focused, color }) => (
        <Ionicons name={route.icon} size={24} color={color} />
      )}
      renderLabel={({ route, focused, color }) => (
        <Text style={{ color, margin: 8 }}>{route.title}</Text>
      )}
      activeColor={theme.colors.yellowPrimary}
      inactiveColor={theme.colors.white}
    />
  );

  return (
    <SafeAreaView style={styles.container}>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width }}
        renderTabBar={renderTabBar}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.gray,
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  sceneContainer: {
    flexGrow: 1,
    padding: theme.spacings.medium,
  },
  stepTitle: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.white,
    marginBottom: theme.spacings.medium,
  },
  descriptionInput: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacings.medium,
    borderRadius: theme.borderRadius.small,
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.white,
    textAlignVertical: 'top',
    minHeight: 200,
    maxHeight: 200,
  },
  charCounter: {
    color: theme.colors.white,
    textAlign: 'right',
    marginTop: theme.spacings.small,
    fontSize: theme.fonts.sizes.small,
  },
  nameInput: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacings.medium,
    borderRadius: theme.borderRadius.small,
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.yellowPrimary,
    fontFamily: theme.fonts.family.bold,
  },
  reviewBackground: {
    flex: 1,
  },
  reviewContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  titleContainer: {
    height: 80,
    paddingHorizontal: theme.spacings.medium,
    backgroundColor: theme.colors.gray,
    justifyContent: 'center',
  },
  reviewTitle: {
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.yellowPrimary,
  },
  kernelContainer: {
    flex: 1,
    backgroundColor: theme.colors.transparent_white,
    padding: theme.spacings.medium,
    marginBottom: theme.spacings.small,
  },
  kernelContentContainer: {
    padding: theme.spacings.medium,
  },
  reviewDescription: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.white,
  },
  finalizeButton: {
    backgroundColor: theme.colors.redPrimary,
    padding: theme.spacings.medium,
    alignItems: 'center',
    justifyContent: 'center',
  },
  finalizeButtonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
  },
  finalizeButtonDisabled: {
    backgroundColor: theme.colors.lightgray,
  },
});

export default WorldCreationFlow;
