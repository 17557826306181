// MiniCardSkeleton.js
import React from 'react';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { CARD_WIDTH, CARD_HEIGHT, CARD_PADDING } from '../MiniCardWithInfo'; // Adjust the path as needed
import theme from '../../styles/theme';

const MiniCardSkeleton = () => (
  <ContentLoader
    speed={1}
    width={CARD_WIDTH + CARD_PADDING * 2}
    height={CARD_HEIGHT + CARD_PADDING * 2}
    backgroundColor={theme.colors.loadingBackground}
    foregroundColor={theme.colors.loadingForeground}
  >
    {/* Single Rectangle Placeholder */}
    <Rect
      x={CARD_PADDING}
      y={CARD_PADDING}
      rx="8" // Rounded corners, adjust as needed
      ry="8"
      width={CARD_WIDTH}
      height={CARD_HEIGHT}
    />
  </ContentLoader>
);

export default MiniCardSkeleton;
