import React, { useEffect, useState, useRef } from 'react';
import { View, Text, StyleSheet, Animated, Easing, Dimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import theme from '../styles/theme';

const windowWidth = Dimensions.get('window').width;

export const WORLD_WIDTH = Math.min(windowWidth - 20, 400);
export const WORLD_HEIGHT = WORLD_WIDTH;
const ASPECT_RATIO = 16 / 9;
const IMAGE_HEIGHT = WORLD_WIDTH / ASPECT_RATIO;

const CreatingWorldComponent = ({ worldName }) => {
  const [typedText, setTypedText] = useState('');
  const typingInterval = useRef(null);

  useEffect(() => {
    let index = 0;
    typingInterval.current = setInterval(() => {
      setTypedText((prev) => prev + worldName.charAt(index));
      index++;
      if (index === worldName.length) clearInterval(typingInterval.current);
    }, 100);

    return () => clearInterval(typingInterval.current);
  }, [worldName]);

  const shimmerAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(shimmerAnimation, {
          toValue: 1,
          duration: 4000,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(shimmerAnimation, {
          toValue: 0,
          duration: 4000,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, [shimmerAnimation]);

  const shimmerTranslate = shimmerAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [-WORLD_WIDTH, 0],
  });

  return (
    <View style={styles.container}>
      <View style={styles.imagePlaceholder}>
        <Animated.View style={[styles.shimmer, { transform: [{ translateX: shimmerTranslate }] }]}>
          <LinearGradient
            colors={[theme.colors.redPrimary, theme.colors.bluePrimary, theme.colors.yellowPrimary]}
            style={styles.gradient}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
          />
        </Animated.View>
      </View>
      <View style={styles.content}>
        <Text style={styles.worldName} numberOfLines={1} ellipsizeMode="tail">
          {typedText.toUpperCase()}
        </Text>
        <View style={styles.summaryContainer}>
          {[...Array(4)].map((_, index) => (
            <View key={index} style={styles.summaryLine}>
              <Animated.View style={[styles.shimmerLine, { transform: [{ translateX: shimmerTranslate }] }]}>
                <LinearGradient
                  colors={[theme.colors.gray, theme.colors.lightgray, theme.colors.gray, theme.colors.lightgray]}
                  style={styles.gradient}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 0 }}
                />
              </Animated.View>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: WORLD_WIDTH,
    height: WORLD_HEIGHT,
    backgroundColor: theme.colors.gray,
    borderRadius: theme.borderRadius.medium,
    overflow: 'hidden',
  },
  imagePlaceholder: {
    width: '100%',
    height: IMAGE_HEIGHT,
    backgroundColor: theme.colors.gray,
    overflow: 'hidden',
  },
  shimmer: {
    width: WORLD_WIDTH * 2,
    height: '100%',
    position: 'absolute',
  },
  gradient: {
    flex: 1,
  },
  content: {
    padding: theme.spacings.small,
    flex: 1,
    justifyContent: 'space-between',
  },
  worldName: {
    color: theme.colors.yellowPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
    marginBottom: theme.spacings.xxsmall,
  },
  summaryContainer: {
    flex: 1,
    justifyContent: 'space-around',
  },
  summaryLine: {
    height: 10,
    backgroundColor: theme.colors.lightgray,
    borderRadius: 5,
    overflow: 'hidden',
  },
  shimmerLine: {
    width: '200%',
    height: '100%',
    position: 'absolute',
  },
});

export default CreatingWorldComponent;
