import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {
  Animated,
  Dimensions,
  Easing,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {FontAwesome, Ionicons, MaterialIcons} from '@expo/vector-icons';
import theme from '../styles/theme';
import {useProfile} from "../contexts/ProfileContext";
import UpgradeModal from "../components/modals/UpgradeModal";
import ChooseFriendModal from "../components/modals/ChooseFriendModal";
import DisclaimerModal from "../components/modals/DisclaimerModal";
import MiniCard from "./MiniCard";
import alert from "../utils/alert";
import SvgRiffLine from "../assets/images/RiffLine";
import {createCard} from "../utils/api/cardsService";
import {formatCharacterCount} from "../utils/helpers";
import UserCircle from "./UserCircle";
import {useFocusEffect} from '@react-navigation/native';
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import SvgFabylHeader from "../assets/images/FabylHeader";
import SvgRiff from "../assets/images/Riff";
import MiniWorldComponent from "./MiniWorldComponent";
import {AuthContext} from "../contexts/AuthContext";
import LoginPromptModal from "./modals/LoginPromptModal";


const MAX_CHARACTERS = 100;
const { height: SCREEN_HEIGHT } = Dimensions.get('window');
const FIXED_HEADER_HEIGHT = SCREEN_HEIGHT * 0.3; // 30% of screen height

const AutoExpandingTextInput = ({ value, onChangeText, placeholder, maxLength, inputRef, onSubmitEditing, onClear }) => {
  const [height, setHeight] = useState(50);
  const isLargeScreen = Platform.OS === 'web';

  return (
    <View style={styles.inputWrapper}>
      <TextInput
        ref={inputRef}
        multiline
        value={value}
        autoCapitalize={'none'}
        onChangeText={onChangeText}
        placeholder={placeholder}
        placeholderTextColor={theme.colors.redPrimaryPlaceholder}
        maxLength={maxLength}
        blurOnSubmit={true}
        returnKeyType="done"
        onContentSizeChange={(event) => {
          setHeight(Math.min(event.nativeEvent.contentSize.height, 150));
        }}
        style={[
          styles.input,
          { height: Math.max(50, height) },
          isLargeScreen && styles.inputLargeScreen,
        ]}
      />
      {value.length > 0 && (
        <View style={styles.clearButtonWrapper}>
          <TouchableOpacity
            style={styles.clearButton}
            onPress={onClear}
            hitSlop={{top: 10, bottom: 10, left: 10, right: 10}}
          >
            <Text style={styles.clearButtonText}>×</Text>
          </TouchableOpacity>
          <Text style={styles.characterCount}>
            {`${formatCharacterCount(value.length)}/${maxLength}`}
          </Text>
        </View>
      )}
    </View>
  );
};

const ExpandedInputScreen = ({ route, navigation }) => {
  const { cardRiffedFrom, returnFromCreation, selectedWorld: initialSelectedWorld } = route.params || {};
  const [selectedWorld, setSelectedWorld] = useState(initialSelectedWorld || null);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const { profileData, markDisclaimerAcknowledged } = useProfile();
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoginModalVisible, setLoginModalVisible] = useState(false);
  const [friendModalVisible, setFriendModalVisible] = useState(false);
  const [disclaimerModalVisible, setDisclaimerModalVisible] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const isLargeScreen = useIsLargeScreen();
  const [animatedText, setAnimatedText] = useState('');
  const typingAnimation = useRef(new Animated.Value(0)).current;
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (initialSelectedWorld) {
      setSelectedWorld(initialSelectedWorld);
    }
  }, [initialSelectedWorld]);

  useLayoutEffect(() => {
    navigation.setOptions({
      // headerTitle: cardRiffedFrom ? "RIFF" : "NEW",
      headerTitle: () => (
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={{fontSize: 18, color: theme.colors.black}}>
            {cardRiffedFrom ? "RIFF" : "NEW"}
          </Text>
          {cardRiffedFrom ? (
            <SvgRiff style={{marginLeft: theme.spacings.xsmall, transform: [{scale: 0.75}]}} wandColor={theme.colors.gray}/>

          ) : (
            // <FontAwesome name="plus" size={18} color={theme.colors.redPrimary}
            //              style={{marginLeft: theme.spacings.xsmall}}/>
            <View></View>
          )}
        </View>
      ),
      headerShown: true,
      headerStyle: {
        backgroundColor: theme.colors.beige,
      },
      headerLeft: () => (
        cardRiffedFrom == null && isAuthenticated ? (
          <TouchableOpacity
            onPress={() => navigation.navigate('UserWorldsList', {onSelectWorld: handleWorldSelect})}
            style={styles.headerLeftContainer}
          >
            <Ionicons
              name="planet-outline"
              size={32}
              color={theme.colors.redPrimary}
            />
            <Text style={styles.headerLeftWorldText}>WORLD</Text>
          </TouchableOpacity>
        ) : null
      ),
      headerRight: () => (
        <TouchableOpacity
          onPress={handleClose}
          hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}
        >
          <MaterialIcons name="close" size={24} color={theme.colors.black} style={{marginRight: 15}}/>
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  useFocusEffect(
    React.useCallback(() => {
      if (!returnFromCreation) {
        setInputValue('');
      }
      // Clear the returnFromCreation flag after using it
      navigation.setParams({ returnFromCreation: false });
    }, [returnFromCreation, navigation])
  );

  useFocusEffect(
    React.useCallback(() => {
      if (inputValue.trim()) {
        setIsCreating(false);
      }
      return () => {
      }; // Optional cleanup function
    }, [inputValue])
  );

  useFocusEffect(
    React.useCallback(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, [])
  );

  const handleClose = () => {
    if (cardRiffedFrom) {
      navigation.navigate('CardDetail', { card: cardRiffedFrom });
    } else {
      navigation.goBack();
    }
  }

  const handleCreatePress = async () => {
    if (!inputValue.trim()) {
      alert("Validation Error", "Name cannot be empty!");
      return;
    }
    if (isAuthenticated && !profileData.disclaimer_acknowledged) {
      setDisclaimerModalVisible(true);
      return;
    }

    setIsCreating(true);
    try {
      const { task_id, uuid } = await createCard(
        inputValue, cardRiffedFrom?.uuid, selectedFriend?.uuid, selectedWorld?.uuid);
      navigation.navigate('FabylCreation', {
        fabylName: inputValue,
        selectedFriend: selectedFriend,
        cardRiffedFrom: cardRiffedFrom,
        task_id: task_id,
        uuid: uuid,
      });
    } catch (error) {
      setIsCreating(false);
      if (error.response && error.response.data && error.response.data.code === 'INSUFFICIENT_GENERATIONS') {
        setModalVisible(true);
      }
      else if (error.response && error.response.data && error.response.data.code === 'INSUFFICIENT_WORLD_GENERATIONS') {
        // alert("Insufficient World Generations", "This world has not generations left. Get fabyl to make more cards.")
        setLoginModalVisible(true);
      }
      else if (error.response && error.response.data && error.response.data.code === 'CURRENT_USER_BLOCKED_RESOURCE_USER') {
        alert("Blocked User Error", "You have blocked a contributor to this world, so you cannot contribute to this world anymore while that user remains blocked.");
      } else {
        alert("Error", error.message || "An error occurred. Please try again.");
      }
    }
  };

  const handleSendToFriendPress = () => {
    setFriendModalVisible(true);
  };
  useEffect(() => {
  return () => typingAnimation.removeAllListeners();
}, []);

  const handleDisclaimerConfirm = () => {
    setDisclaimerModalVisible(false);
    markDisclaimerAcknowledged();
    handleCreatePress();
  };

  const handleDisclaimerCancel = () => {
    setDisclaimerModalVisible(false);
  };

  const handleSuggestionPress = (title) => {
    setInputValue(title);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const closeFriendModal = () => {
    setSelectedFriend(null); // Clear the selected friend's data (if any
    setFriendModalVisible(false);
  };

  const handleFriendSelect = (friend) => {
    if (selectedFriend?.uuid === friend?.uuid) {
      // If the friend is already selected and not null, navigate to their profile
      setSelectedFriend(null)
      // if (friend !== null) {
      //   navigation.navigate('UserProfile', {profile_uuid: friend.uuid});
      // }
    } else {
      // Otherwise, select the friend (can be null)
      setSelectedFriend(friend);
    }
  };

  const handleWorldSelect = (world) => {
    if (selectedWorld?.uuid === world?.uuid) {
      // If the world is already selected and not null, navigate to its detail page
      setSelectedWorld(null);
      // if (world !== null) {
      //   navigation.navigate('World', {worldUuid: world.uuid});
      // }
    } else {
      // Otherwise, select the world (can be null)
      setSelectedWorld(world);
    }
  };

  const handleInspireMe = () => {
    const suggestedTitles = [
      "The Shadow Shaman's Sandwich",
      "Hyperspeed Potato Cooker",
      "Sir Whiskers and the Moon Cheese",
      "Salamander Stewie",
      "The Bovinitude of Being",
      "Working Miracles with a Waffle Iron",
      "Boblin The Goblin",
      "I like to play blobsball",
      "it tastes like blueberries...",
      "\"Hey you! Get off my cloud!\"",
      "The Quantum Detective's Puzzling Case",
      "Zara and the Portal to Nowhere",
      "The Singing Swords of Silverwind",
      "The Ghost Ship's Echoing Laughter",
    ];
    const randomTitle = suggestedTitles[Math.floor(Math.random() * suggestedTitles.length)];

    setInputValue('');
    setAnimatedText('');
    typingAnimation.setValue(0);

    Animated.timing(typingAnimation, {
      toValue: randomTitle.length,
      duration: randomTitle.length * 50,
      easing: Easing.linear,
      useNativeDriver: false
    }).start();

    typingAnimation.addListener(({value}) => {
      const newText = randomTitle.slice(0, Math.floor(value));
      setAnimatedText(newText);
      setInputValue(newText); // Set input value along with animated text
    });

    // Set full text after animation completes
    setTimeout(() => {
      setInputValue(randomTitle);
      setAnimatedText(''); // Clear animated text after animation
      typingAnimation.removeAllListeners();
    }, randomTitle.length * 50 + 100);
  };

  // console.log(`selectedWorld`, selectedWorld);

  const handleInputChange = (text) => {
    setInputValue(text);
    if (text.length < inputValue.length) {
      // Backspace detected
      setAnimatedText('');
    }
  };

  const handleWorldPress = () => {
    navigation.navigate('UserWorldsList', { onSelectWorld: handleWorldSelect });
  }

  const handleClearInput = () => {
    setInputValue('');
    setAnimatedText('');
  };

  const getPlaceholderText = () => {
    if (cardRiffedFrom) {
      return "NAME THE NEXT CHAPTER...";
    } else if (selectedWorld) {
      return `NAME YOUR FABYL IN "${selectedWorld.name.toUpperCase()}"...`;
    } else {
      return "NAME YOUR FABYL...";
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "padding"}
      style={styles.container}
      keyboardVerticalOffset={Platform.OS === "ios" ? 130 : 130}
    >
      <SafeAreaView style={styles.safeArea}>
        <View style={styles.fixedHeader}>
          <View>
            {cardRiffedFrom ? (
              <View style={styles.miniCardContainer}>
                <View style={{zIndex: 1}}>
                  <MiniCard item={cardRiffedFrom} pressable={false}/>
                </View>
                <SvgRiffLine style={[styles.riffLine, {transform: [{scale: 0.8}]}]}/>
              </View>
            ) : (
              <>
                {selectedWorld ? (
                  <View style={styles.selectWorldButton}>
                  <MiniWorldComponent
                    world={selectedWorld}
                    disableTouch={false}  // Change this to false to enable internal touches
                    handleClose={() => setSelectedWorld(null)}
                  />
                  </View>
                ) : (
                  <View style={{alignItems: "center", marginTop: theme.spacings.xlarge}}>
                    <SvgFabylHeader />
                  </View>
                )}
              </>
            )}
          </View>
          <View style={styles.infoContainer}>
            {isAuthenticated ? (
                <Text style={[styles.infoText, styles.charactersText]}>
                  <FontAwesome name="plus" size={10} color={theme.colors.redPrimaryPlaceholder} style={{marginRight: theme.spacings.small}}/>
                  {`${formatCharacterCount(profileData?.remaining_generations)}/${profileData?.daily_generations} left`}
                </Text>
              ) :
              (
                <View/>
              )
            }
            <Text style={styles.infoText}>*fabyls are public</Text>
          </View>
        </View>

        <View style={[
          styles.contentContainer,
        ]}>
          <View style={styles.inputWrapper}>
            <View style={styles.inputContainer}>
              <AutoExpandingTextInput
                value={animatedText || inputValue}
                onChangeText={handleInputChange}
                // placeholder={cardRiffedFrom ? "NAME THE NEXT CHAPTER..." : "NAME YOUR FABYL..."}
                placeholder={getPlaceholderText()}
                maxLength={MAX_CHARACTERS}
                inputRef={inputRef}
                onSubmitEditing={handleCreatePress}
                onClear={handleClearInput}
              />
            </View>
          </View>

        <View style={styles.optionsContainer}>
          {isAuthenticated && (
            <View style={styles.leftOptions}>
                <TouchableOpacity
                  style={styles.sendToFriendButton}
                  onPress={handleSendToFriendPress}
                  hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}
                >
                  {selectedFriend ? (
                    <UserCircle profile_uuid={selectedFriend.uuid} size={25}/>
                  ) : (
                    <FontAwesome name="user-circle-o" size={20} color={theme.colors.gray}/>
                  )}
                </TouchableOpacity>
            </View>
          )}
          <TouchableOpacity
            style={[
              styles.inspireButton,
              !(!inputValue.trim() || isCreating) && styles.createButtonDisabled,
            ]}
            onPress={handleInspireMe}
            disabled={!(!inputValue.trim() || isCreating)}
          >
            <Text style={[
              styles.inspireButtonText,
              !(!inputValue.trim() || isCreating) && styles.createButtonTextDisabled,
            ]}>
              INSPIRE ME
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.createButton,
              (!inputValue.trim() || isCreating) && styles.createButtonDisabled,
            ]}
            onPress={handleCreatePress}
            disabled={!inputValue.trim() || isCreating}
            hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}
          >
            <Text
              style={[styles.createButtonText, (!inputValue.trim() || isCreating) && styles.createButtonTextDisabled]}>
              {'CREATE'}
            </Text>
          </TouchableOpacity>
        </View>
        </View>

        <UpgradeModal
          isVisible={modalVisible}
          onConfirm={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
        />
        <LoginPromptModal
          isVisible={isLoginModalVisible}
          onConfirm={() => console.log('User wants to sign up or log in')}
          onCancel={() => setLoginModalVisible(false)}
          message={"Sign up to make cards in this world."}
        />
        <ChooseFriendModal
          visible={friendModalVisible}
          onClose={closeFriendModal}
          onSelectFriend={(friend) => {
            setSelectedFriend(friend);
            setFriendModalVisible(false);
          }}
        />
        <DisclaimerModal
          isVisible={disclaimerModalVisible}
          onConfirm={handleDisclaimerConfirm}
          onCancel={handleDisclaimerCancel}
        />
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: theme.colors.beige,
  },
  container: {
    flex: 1,
    backgroundColor: theme.colors.beige,
    height: "100%",
  },
  contentContainer: {
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 600,
    alignSelf: 'center',
    backgroundColor: theme.colors.beige,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacings.medium,
    right: theme.spacings.medium,
    zIndex: 1,
  },
  inputWrapper: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    backgroundColor: theme.colors.white,
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  inputLargeScreen: {
    borderRadius: theme.borderRadius.large,
  },
  infoContainer: {
    maxWidth: 600,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: theme.spacings.medium,
    marginVertical: theme.spacings.xsmall,
  },
  optionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: theme.spacings.medium,
    marginTop: theme.spacings.medium,
  },
  leftOptions: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  upperOptions: {
    alignSelf: "center",
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: "space-between",
    width: "50%",
  },
  infoText: {
    fontSize: theme.fonts.sizes.small,
    color: theme.colors.lightgray,
    alignSelf: 'center',
  },
  charactersText: {
    fontFamily: theme.fonts.family.monospace,
  },
  createButtonLargeScreen: {
    borderRadius: theme.borderRadius.large,
  },
  createButtonDisabled: {
    backgroundColor: theme.colors.verylightgray,
  },
  createButtonTextDisabled: {
    color: theme.colors.white,
  },
  sendToFriendButton: {
    alignSelf: 'flex-start',
  },
  miniCardContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 600,
  },
  fabylHeaderContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 600,
    flex: 1,
  },
  suggestedTitlesBelowContainer: {
    flex: 2,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    maxWidth: 600,
    width: '100%',
  },
  brandContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  createWorldButton: {
    padding: theme.spacings.medium,
    marginRight: theme.spacings.small,
    backgroundColor: theme.colors.lighterBlue,
  },
  selectedWorldName: {
    marginLeft: theme.spacings.small,
    fontSize: theme.fonts.sizes.small,
    color: theme.colors.bluePrimary,
    maxWidth: 100,
  },
  fixedHeader: {
    alignItems: 'center',
    height: FIXED_HEADER_HEIGHT,
    backgroundColor: theme.colors.beige,
    justifyContent: "space-between",
    // marginVertical: theme.spacings.small,
  },
  inspireButton: {
    backgroundColor: theme.colors.yellowPrimary,
    paddingVertical: theme.spacings.xsmall,
    paddingHorizontal: theme.spacings.medium,
    borderRadius: theme.borderRadius.large,
    alignSelf: "center",
  },
  createButton: {
    backgroundColor: theme.colors.bluePrimary,
    paddingVertical: theme.spacings.xsmall,
    paddingHorizontal: theme.spacings.medium,
    borderRadius: theme.borderRadius.large,
    alignSelf: "center",
  },
  inspireButtonText: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
  },
  createButtonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
  },
  input: {
    flex: 1,
    paddingHorizontal: theme.spacings.medium,
    paddingTop: theme.spacings.small,
    paddingBottom: theme.spacings.small,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.redPrimary,
    backgroundColor: theme.colors.white,
    outlineStyle: 'none', // Removes the outline style
    outlineWidth: 0, // Sets the outline width to 0
  },
  clearButtonWrapper: {
    alignItems: 'center',
    flexDirection: "column",
    backgroundColor: theme.colors.white,
    marginHorizontal: theme.spacings.xsmall,
  },
  clearButton: {
    width: 16,
    height: 16,
    borderRadius: 8, // Half of width/height for a perfect circle
    backgroundColor: theme.colors.verylightgray,
    justifyContent: 'center',
    alignItems: 'center',
  },
  clearButtonText: {
    color: theme.colors.lightgray,
    fontSize: 14,
    fontWeight: 'bold',
    justifyContent: "center",
    textAlign: 'center',
    lineHeight: 16, // Match the height of the button
    includeFontPadding: false, // Remove any extra padding
  },
  characterCount: {
    marginTop: 4,
    fontSize: theme.fonts.sizes.small,
    color: theme.colors.lightgray,
    fontFamily: theme.fonts.family.monospace,
  },
  riffLine: {
    marginTop: -15,
    zIndex: 0,
  },
  selectWorldButton: {
    alignSelf: 'center',
    margin: theme.spacings.medium,
    padding: theme.spacings.small,
    position: 'relative', // This allows absolute positioning of children
    // width: '100%', // Ensure it takes full width
    maxWidth: 600, // Match the maxWidth of miniCardContainer
  },
  clearWorldButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: theme.colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  clearWorldButtonText: {
    color: theme.colors.gray,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 24,
    textAlign: 'center',
  },
  headerLeftContainer: {
    marginLeft: 15,
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerLeftWorldText: {
    color: theme.colors.redPrimary,
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
    textAlignVertical: 'center',
    marginLeft: theme.spacings.xsmall,
  },
  image: {
    width: 130,
    height: 200,
  },
});

export default ExpandedInputScreen;
